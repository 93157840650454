<template>
  <v-card class="nospace">
    <v-layout row>
      <v-btn fab flat @click.native="back()" class="pt-1">
        <v-icon class="pointer" v-ripple>arrow_back</v-icon>
      </v-btn>
      <v-flex xs2 style="padding-top: 18px"> <h2>Marked off</h2></v-flex>
      <v-flex xs5>
        <v-text-field
          v-model="search"
          placeholder="Search Reference Number"
          @keyup.enter="searchAnything"
          append-icon="search"
        ></v-text-field>
      </v-flex>
      <v-flex
        xs5
        pl-3
        style="padding-top: 18px"
        class="wrapper"
        id="accountDate"
      >
        <ejs-daterangepicker
          id="approvalPicker"
          :placeholder="waterMark"
          v-model="dateRange2"
        ></ejs-daterangepicker>
      </v-flex>
      <v-spacer> </v-spacer>

      <v-flex xs2 pt-2 pl-4>
        <v-btn
          v-if="items.length"
          flat
          outline
          class=""
          color="indigo"
          @click.native="download()"
        >
          <v-icon color="indigo">download</v-icon>
          download Report
        </v-btn>
      </v-flex>
      <v-flex xs1 pt-2 pl-1 justify-end>
        <v-btn
          class="reset-btn-color pt-1"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <v-icon color="orange">donut_large</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-data-table
      id="jobTable"
      hide-actions
      item-key="receiptNumber"
      :headers="headers"
      :disable-initial-sort="true"
      single-expand
      show-expand
      :items="items"
      :pagination.sync="pagination"
    >
      <template slot="items" slot-scope="props">
        <tr style="cursor: pointer" @click="props.expanded = !props.expanded">
          <td style=" min-width: 200px; !important">
            <v-layout row justify-center>
              <span class="margin-t">
                <activityLogs
                  :type="'3'"
                  :_id="props.item._id"
                  :id="props.item.receiptNumber"
                  :subType="15"
                /> </span
              >&nbsp;
              <span>
                {{ props.item.receiptNumber }}
              </span>
            </v-layout>
          </td>
          <td style="min-width: 180px; !important">
            {{ getTime4(props.item.actualReceivedDate) }}
          </td>

          <td style=" min-width: 180px; !important">
            {{ props.item.amount.toFixed(2) }} {{ props.item.customerCurrency }}
          </td>

          <td style=" min-width: 180px; !important">
            {{ props.item.balanceAmount.toFixed(2) }}
            {{ props.item.customerCurrency }}
          </td>
          <td style=" min-width: 180px; !important">
            {{ props.item.adjustedAmount.toFixed(2) }}
            {{ props.item.customerCurrency }}
          </td>
          <td style="min-width: 180px; !important">
            {{ props.item.createdBy }} <br />
            {{ props.item.employeeID }}
          </td>
        </tr>
      </template>
      <template slot="expand" slot-scope="props">
        <MarkedOffRefrenceList
          :refernceId="props.item._id"
          :custId="customerId"
        />
      </template>
    </v-data-table>

    <pagination
      :totalPages="totalPages"
      :pages="this.pages"
      @pageNo="page"
      :url="listApiUrl"
      :callback="callback"
      :componentKey="componentKey"
      :action="`customerList`"
      :payload="payload"
      :type="1"
      v-on:pagination-load="checkLoading"
    />
  </v-card>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import pagination from "@/components/Pagination/pagination";
import { openmarkedOffCustomerPayment } from "@/constants/datatable-headers.js";
import MarkedOffRefrenceList from "@/components/Accounts/CustomerPayment/Listing/MarkedOffRefrenceList.vue";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys, Banks } from "@/constants/constants";
import activityLogs from "@/components/Common/activityLogs";
import { markOff } from "@/constants/api-urls.js";
import downloadMixin from "@/mixins/downloadMixin";
import { EventBus } from "../../../../event-bus.js";
export default {
  mixins: [downloadMixin],
  created() {
    this.$eventBus.$on("customerRecipt", (value) => {
      this.refreshList();
    });

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.track("Sales Section", {
        email: localStorage.getItem("email"),
        name: localStorage.getItem("user"),
        "Working Country": workingCountry.toString(),
      });
      this.payload = {
        customer_id: this.$props.customerId,
      };
    }
  },
  components: {
    pagination,
    SuccessDialog,
    MarkedOffRefrenceList,
    activityLogs,
  },
  props: {
    customerId: String,
  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
          this.$emit("daterangenew-cust-pay", this.dateRange2);
          this.$set(this.payload, "adjustDateRange", {
            start: this.startDate,
            end: this.endDate,
          });
        }
      } else {
        this.startDate = "";
        this.endDate = "";
      }
    },
  },
  data() {
    return {
      workingCountry: null,
      singleExpand: true,
      selectedids: [],
      selectednames: [],

      waterMark: "Select Date Range",
      e1: 1,
      steps: 1,
      dateRange2: null,
      checkBox: true,
      selectedValue: "",
      search: "",
      title: null,
      dialog1: false,
      componentKey: 0,
      count: 0,
      totalPages: 0,
      checkDone: true,
      listApiUrl: markOff,
      headers: openmarkedOffCustomerPayment,
      pagination: {},
      payload: {},
      items: [],
      pages: 1,
    };
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
        this.selectedValue = "";
      } else {
        this.$emit("off-load", false);
        this.selectedValue = "";
      }
    },

    refreshList() {
      this.componentKey = this.componentKey + 1;
    },
    back() {
      this.$emit("back-button");
    },

    page(event) {
      this.pages = event;
    },
    getTime4(date) {
      return moment(date).format("ll");
    },
    searchAnything() {
      if (this.search.trim().length > 0) {
        this.$set(this.payload, "receiptNumber", this.search);
      }
    },
    reset() {
      this.search = "";
      this.dateRange2 = null;

      this.payload = {
        pageNo: 1,
        customer_id: this.$props.customerId,
      };
    },

    download() {
      let url = [];
      if (this.startDate && this.endDate) {
        url.push(`start=${this.startDate}`);
        url.push(`end=${this.endDate}`);
      }

      url.push(`customer_id=${this.$props.customerId}`);
      if (this.search.trim().length > 0) {
        url.push(`receiptNumber=${this.search}`);
      }

      delete this.axios.defaults.headers.common["token"];

      let finalUrl = "/customerPayment/payment/downloadMarkoffReport?";
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";
      let fileName = `markedOff_report_`;
      this.DownloadExcel(this.constants.apiUrl + finalUrl, fileName);
    },

    callback(res) {
      this.items = [...res.data];

      // this.items.filter((e))
      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
.td {
  min-width: 200px;
}
.reset-btn {
  text-transform: capitalize;
  width: 100%;
}
.margin-t {
  margin-top: -2px;
}
.openMarked-btn-color {
  background-color: #042f71 !important;
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
#accountDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#accountDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 100px !important;
  width: 100%;
  text-align: right;
}
#accountDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#accountDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
</style>
