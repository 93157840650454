<template>
  <v-card class>
    <loading :active.sync="loading" />
    <v-tabs
      fixed-tabs
      class="m-b-20 pt-0"
      background-color="darkorange"
      dark
      v-model="changeTab"
    >
      <v-tab :href="`#paymentDetail`" @click="filterByTab('paymentDetail')"
        >Job Payment Details
      </v-tab>
      <v-tab :href="`#advanceDetail`" @click="filterByTab('advanceDetail')"
        >Assignment Payment Details
      </v-tab>
      <!-- <v-tab
        v-if="workingCountry.includes('UAE') || workingCountry.includes('KSA')"
        :href="`#customerDetail`"
        @click="filterByTab('customerDetail')"
        >Customer Receipt
      </v-tab> -->
    </v-tabs>
    <v-flex xs2 class="reset__btn">
      <AssignmentInvoicePopup
        v-if="
          this.searchTypes.abbr == 'jobId' ||
          this.searchTypes.abbr == 'assignmentId'
        "
        :assignmentIds="this.selectednames"
        :countInvoice="generateInvoiceCount"
        :selectedCustomerForInvoice="selectedCustomerDataInvoice"
        @reset-list="resetInvoiceIds"
      />
      <MultipleAssignmentPopup
        v-if="this.searchTypes.abbr == 'driverId'"
        :assignmentIds="this.selectednames"
        :countInvoice="generateInvoiceCount"
        :type="'Driver'"
        :selectedCustomerForInvoice="selectedCustomerDataInvoice"
        @payment-list-refresh-multiple="refreshMultiple()"
        @reset-list="resetInvoiceIds"
      />
      <MultipleAssignmentPopup
        v-if="this.searchTypes.abbr == 'transporterId'"
        :type="'Transporter'"
        :assignmentIds="this.selectednames"
        :countInvoice="generateInvoiceCount"
        :selectedCustomerForInvoice="selectedCustomerDataInvoice"
        @payment-list-refresh-multiple="refreshMultiple()"
        @reset-list="resetInvoiceIds"
      />
    </v-flex>

    <v-card-title
      class="bg-clr"
      style="margin-top: 5px"
      v-if="
        advanceRequestedAmount ||
        advanceRequested ||
        advanceReadyToPayAmount ||
        advanceReadyToPay ||
        advancePaidTotalAmount ||
        advancePaidAmount ||
        finalReadyToPay ||
        finalPayment ||
        readyToPayAmount ||
        readyToPay ||
        failedAdvanceAmount ||
        failedAdvanceAmountCount ||
        totalPendingFinalPayAmount ||
        totalPendingFinalPayCount ||
        finalPaymentRbFailedAmount ||
        finalPaymentRbFailedCount ||
        totalFinalFailedAmount ||
        totalFinalFailedCount ||
        internalFailedCount ||
        internalFailedAmount
      "
    >
      <v-layout v-if="advanceStatus && assignStatus"></v-layout>
      <v-layout
        v-else-if="assignStatus == 'Driver Partially Assigned'"
      ></v-layout>
      <v-layout
        style="padding: 8px; padding-bottom: 0px; padding-left: 47px"
        v-else
      >
        <v-flex xs3 pr-6 v-if="advanceStatus && !assignStatus">
          <v-layout>
            <span> Advance Requested</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ advanceRequestedAmount || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ advanceRequested || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3 pr-4 v-if="advanceStatus && !assignStatus">
          <v-layout>
            <span>Advance Ready to Pay</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  advanceReadyToPayAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ advanceReadyToPay || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3 pr-4 v-if="advanceStatus && !assignStatus">
          <v-layout>
            <span> Advance Paid</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  advancePaidTotalAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ advancePaidAmount || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex xs3 pr-4>
          <v-layout>
            <span> Final Payment</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  finalReadyToPay || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ finalPayment || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex> -->
        <!-- <v-flex xs3 pr-2 pl-3>
          <v-layout>
            <span> Ready To Pay</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  readyToPayAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ readyToPay || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex> -->
        <v-flex xs3 pr-2 pl-3 v-if="advanceStatus && !assignStatus">
          <v-layout>
            <span> Advance Failed To Pay</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  failedAdvanceAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex
              v-if="failedAdvanceAmountCount > 0 && rbretryButtonShow"
              xs4
              pt-3
            >
              <span class="muted">Count</span>
            </v-flex>
            <v-flex v-else xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ failedAdvanceAmountCount || "0" }}</span>
                <v-btn
                  v-if="failedAdvanceAmountCount > 0 && rbretryButtonShow"
                  class="advance-btn-color pl-2"
                  style="padding: 15px !important"
                  flat
                  prepend-icon
                  color="white "
                  @click="openRetryAllpopup('advance')"
                >
                  Retry All</v-btn
                >

                <div
                  style="
                    color: red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px !important;
                  "
                  class="pl-1"
                  v-if="failedAdvanceAmountCount > 0 && !rbretryButtonShow"
                >
                  <v-tooltip
                    top
                    content-class="tooltip-op"
                    style="margin: 0 10px 2px 0 !important"
                  >
                    <span
                      slot="activator"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white !important;
                        background: red !important;
                        padding: 3px !important;
                        border-radius: 100% !important;
                        height: 20px !important;
                        width: 20px !important;
                        text-align: center !important;
                      "
                    >
                      i
                    </span>
                    <span class="tooltip">Connect To Technical team</span>
                  </v-tooltip>
                </div>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          xs3
          pr-2
          pl-3
          v-if="!advanceStatus && assignStatus == 'Payment Pending'"
        >
          <v-layout>
            <span> Total Final Pending</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new pl-4"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  totalPendingFinalPayAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{
                  totalPendingFinalPayCount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3 pr-2 pl-3 v-if="!advanceStatus && assignStatus">
          <v-layout>
            <span> Total Final Failed</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  totalFinalFailedAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ totalFinalFailedCount || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs3
          pr-2
          pl-3
          v-if="!advanceStatus && assignStatus == 'Payment Pending'"
        >
          <v-layout>
            <span> OMS Failed Final Payment</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  internalFailedAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{ internalFailedCount || "0" }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs3
          pr-2
          pl-3
          v-if="!advanceStatus && assignStatus == 'Payment Pending'"
        >
          <v-layout>
            <span>RB Failed Final Payment</span>
          </v-layout>

          <v-layout>
            <v-flex xs3 pt-3>
              <span class="muted">Amount</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar new"
                style="min-width: 0px !important"
              >
                <span class="count breakword">{{
                  finalPaymentRbFailedAmount || "0"
                }}</span>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex
              v-if="finalPaymentRbFailedCount > 0 && rbretryButtonShowFinal"
              xs4
              pt-3
            >
              <span class="muted">Count</span>
            </v-flex>
            <v-flex v-else xs3 pt-3>
              <span class="muted">Count</span>
            </v-flex>
            <v-flex>
              <v-avatar
                tile
                color="#ffa500"
                class="total avatar"
                style="min-width: 0px !important"
              >
                <span class="count">{{
                  finalPaymentRbFailedCount || "0"
                }}</span>
                <v-btn
                  v-if="finalPaymentRbFailedCount > 0 && rbretryButtonShowFinal"
                  class="advance-btn-color pl-2"
                  style="padding: 15px !important"
                  flat
                  prepend-icon
                  color="white "
                  @click="openRetryAllpopup('final')"
                >
                  Retry All</v-btn
                >

                <div
                  style="
                    color: red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px !important;
                  "
                  class="pl-1"
                  v-if="
                    finalPaymentRbFailedCount > 0 && !rbretryButtonShowFinal
                  "
                >
                  <v-tooltip
                    top
                    content-class="tooltip-op"
                    style="margin: 0 10px 2px 0 !important"
                  >
                    <span
                      slot="activator"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white !important;
                        background: red !important;
                        padding: 3px !important;
                        border-radius: 100% !important;
                        height: 20px !important;
                        width: 20px !important;
                        text-align: center !important;
                      "
                    >
                      i
                    </span>
                    <span class="tooltip">Connect To Technical team</span>
                  </v-tooltip>
                </div>
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs3
          pr-2
          pl-3
          v-if="
            !advanceStatus &&
            assignStatus == 'Payment Pending' &&
            finalPaymentRbFailedCount > 0
          "
        >
          <v-layout>
            <!-- <span> Total RB Failed Entry for Final Payment </span> -->
          </v-layout>
          <v-switch
            color="green"
            v-model="finalPaymentStatus"
            :label="'See Failed  Final Payments'"
            @change="searchData()"
          ></v-switch>
          <!-- <v-radio-group
            v-model="finalPaymentStatus"
            :mandatory="true"
            :rules="[rules.required]"
            @change="searchData()"
          >
            <div>
              <div class>
                <v-radio
                  label=" Final Pending Payments"
                  value="false"
                  color="black"
                  class="col-md-6"
                ></v-radio>

                <v-radio
                  label=" Failed Rb Final Payments"
                  value="true"
                  color="black"
                  class="col-md-6"
                ></v-radio>
              </div>
            </div>
          </v-radio-group> -->
        </v-flex>
      </v-layout>
    </v-card-title>

    <v-container fluid>
      <v-layout row pt-0>
        <v-flex xs3>
          <v-autocomplete
            v-model="searchTypes"
            :items="searchItems"
            label="Search Type"
            item-text="status"
            single-line
            persistent-hint
            item-value="abbr"
            @change="searchData()"
            return-object
          ></v-autocomplete>
        </v-flex>
        <v-divider class="divider-style" vertical></v-divider>
        <v-flex xs4 pr-3>
          <v-text-field
            class="gap searchAccounts"
            :disabled="!searchTypes"
            :label="'Search here'"
            single-line
            v-model="search"
            append-icon="search"
            @keyup.enter="searchData()"
            @click:append="searchData()"
          ></v-text-field>
        </v-flex>

        <v-flex xs3>
          <v-autocomplete
            v-model="driverSearchTypes"
            :items="driverSearchItems"
            label="Search Type"
            item-text="status"
            single-line
            item-value="abbr"
            @change="searchData()"
            return-object
          ></v-autocomplete>
        </v-flex>
        <v-divider class="divider-style" vertical></v-divider>
        <v-flex xs4 pr-3>
          <v-text-field
            class="gap searchAccounts"
            :label="'Search here'"
            :disabled="!driverSearchTypes"
            single-line
            v-model="driverSearch"
            append-icon="search"
            @keyup.enter="searchData()"
            @click:append="searchData()"
          ></v-text-field>
        </v-flex>
        <v-flex xs3 pt-3 pr-3>
          <v-autocomplete
            label="Truck Type"
            :items="subTruck"
            class="pt-0 currencyTitle"
            v-model="subTruckType"
            @change="searchData()"
            item-text="text"
            item-value="value"
            single-line
          />
        </v-flex>
        <v-flex xs3 pt-3 pr-3>
          <v-autocomplete
            class="pt-0 currencyTitle"
            :items="advanceStatusList"
            v-model="advanceStatus"
            label="Advance Status"
            single-line
            autocomplete="offfadsf"
            @change="searchData()"
          />
        </v-flex>
        <v-flex xs4 pt-3 pr-3>
          <v-autocomplete
            class="pt-0 currencyTitle"
            :items="statusList"
            v-model="assignStatus"
            label="Assign. Status"
            single-line
            autocomplete="offfadsf"
            @change="searchData()"
          />
        </v-flex>

        <v-flex xs4 pt-3 pr-3 id="jobDate" class="wrapper date_9">
          <ejs-daterangepicker
            :placeholder="waterMark"
            v-model="dateRange2"
          ></ejs-daterangepicker>
        </v-flex>

        <v-flex>
          <v-card-actions class="justify-end">
            <v-btn
              class="reset-btn-color"
              style="padding: 21px !important"
              flat
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card-text class="pt-0 nospace">
      <v-data-table
        id="jobsTable"
        hide-actions
        :headers="headers"
        item-key="_id"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-layout row>
              <v-checkbox
                v-if="showCheckbox && items.length"
                v-model="props.item.selected"
                :disabled="
                  checkDataForSIGeneration(
                    props.item.isPurchaseInvoiceGenerated,
                    props.item.invoicePdfUrl,
                    props.item.assignmentStatus,

                    props.item.paymentAmount,
                    props.item.totalAdditionalAmount,
                    props.item.totalAdvancePaidAmount,
                    props.item.amountPaidToDriver,
                    props.item.workingCountry,
                    props.item.isEntryFailedForFinalPayments
                  )
                "
                @change="
                  checkSelected(props.item.selected, props.item._id, $event)
                "
              >
              </v-checkbox>
              <activityLogs
                :type="'assignment'"
                :permissions="'activity-logs-for-accounts'"
                :_id="props.item._id"
                :id="props.item.assignmentId.toString()"
              />

              <span style="padding-top: 4px !important; padding-left: 3px">
                {{ props.item.jobId }}
              </span></v-layout
            >
            {{ getTime3(props.item.pickupDate) }}
            {{ props.item.pickupTime }}
          </td>
          <td v-if="props.item.isEntryFailedForFinalPayments">
            <span
              style="color: blue"
              v-if="props.item.assignmentType == 'Value Added Service'"
            >
              {{ props.item.assignmentId }}</span
            >
            <span v-else> {{ props.item.assignmentId }}</span
            ><br />
            <div class="red-text2">
              ({{ assignmentStatus(props.item.assignmentStatus) || "-" }})
            </div>
          </td>
          <td v-else>
            <span
              style="color: blue"
              v-if="props.item.assignmentType == 'Value Added Service'"
            >
              {{ props.item.assignmentId }}</span
            >
            <span v-else> {{ props.item.assignmentId }}</span
            ><br />({{ assignmentStatus(props.item.assignmentStatus) || "-" }})
          </td>
          <td>
            {{
              props.item.name || props.item.customer || props.item.companyName
            }}
            <br />{{
              props.item.customerPhoneNumber
                ? props.item.customerPhoneNumber
                : ""
            }}<CustomerDetailPopUp :detail="props.item" />
          </td>
          <td style=" min-width: 150px; !important">
            <span v-if="props.item.driverData">
              {{
                props.item.driverData.firstName +
                " " +
                props.item.driverData.lastName
              }}<br />{{
                props.item.driverData.countryCode +
                "-" +
                props.item.driverData.phoneNo
              }}</span
            >
            <span v-else>N.A</span>
            <ViewDetails
              v-if="props.item.driverData"
              :detail="props.item.driverData"
              :headers="driverDetailHeaders"
              :title="'Driver'"
            />
            <span
              v-if="
                props.item.finalPaymentWithCreditDaysDriver &&
                props.item.assignmentStatus != 4
              "
              class="font_Bold"
            >
              <span
                v-if="getDays(props.item.finalPaymentWithCreditDaysDriver) > 5"
                class="orange-text"
              >
                Credit Days:
                {{ getDays(props.item.finalPaymentWithCreditDaysDriver) }}
              </span>
              <span v-else class="red-text">
                Credit Days:
                {{ getDays(props.item.finalPaymentWithCreditDaysDriver) }}
              </span>
            </span>
          </td>
          <td>
            <div v-if="props.item.transporterData">
              {{ props.item.transporterData.transporterName || "-" }}<br />{{
                props.item.transporterData.countryCode +
                "-" +
                props.item.transporterData.phoneNo
              }}
            </div>
            <div v-else>N.A</div>
            <ViewDetails
              v-if="props.item.transporterData"
              :detail="props.item.transporterData"
              :headers="transporterDetailHeaders"
              :title="'Transporter'"
            />
            <v-flex> </v-flex>
          </td>
          <td>
            <v-flex v-if="props.item.driverData">
              <v-tooltip
                right
                class="milestone"
                content-class="tooltip-op"
                color="orange"
              >
                <template slot="activator" v-if="props.item.truckNumber">
                  {{ props.item.truckPrefix }}-{{
                    props.item.truckNumber
                  }}</template
                >
                <template slot="activator" v-else>
                  {{ props.item.driverData.truckPrefix }}-{{
                    props.item.driverData.truckNumber
                  }}</template
                >
                <span class="tooltip" v-if="props.item.jobType === '1'"
                  >{{ getText(subTruck, props.item.truckType) }}
                </span>
                <span class="tooltip" v-if="props.item.jobType === '2'">
                  {{ getText(airTypes, props.item.truckType.toString()) }}</span
                >
                <span class="tooltip" v-if="props.item.jobType === '3'">
                  {{ getText(seaTypes, props.item.truckType.toString()) }}</span
                >
              </v-tooltip>
            </v-flex>
            <v-flex v-else> N.A </v-flex>
          </td>
          <td>
            {{ props.item.perAssignmentPrice }}
            {{ props.item.customerCurrency }}
          </td>
          <td v-if="props.item.paymentAmount">
            {{ props.item.paymentAmount }} {{ props.item.customerCurrency }}
            <confirmationPopup
              v-if="props.item.assignmentStatus == 8"
              v-permissions="'approve-reject-driver-price'"
              :message="`Are you sure you want to approve higher driver price( ${props.item.paymentAmount} ${props.item.customerCurrency}) than the customer price(${props.item.perAssignmentPrice} ${props.item.customerCurrency}) for assignment id ${props.item.assignmentId}?`"
              :confirmationTitle="`Approve Driver Price For Assignment Id ${props.item.assignmentId}`"
              :cancelBtn="'Cancel'"
              :confirmBtn="'Approve'"
              :customerData="props.item"
              :type="'driverApprove'"
              :assignmentId="props.item.assignmentId"
              @refresh-list="refresh()"
            />
            <CancellationPopup
              v-if="props.item.assignmentStatus == 8"
              v-permissions="'approve-reject-driver-price'"
              :message="`Are you sure you want to Reject higher driver price for this assignment ?`"
              :confirmationTitle="`Reject Driver Price For Assignment Id ${props.item.assignmentId}`"
              :cancelBtn="'Cancel'"
              :type="'driverApprove'"
              :assignmentId="props.item.assignmentId"
              :confirmBtn="'Reject'"
              :customerData="props.item"
              @refresh-list="refresh()"
            />
          </td>
          <td v-else>N.A</td>
          <td v-if="props.item.invoiceAmount">
            {{ props.item.invoiceAmount.toFixed(2) }}
            {{ props.item.customerCurrency }}
          </td>
          <td v-else>N.A</td>
          <td v-if="props.item.jobData.isJobActivatedByScript">N.A</td>
          <td v-else>
            <v-layout justify-center>
              <activityLogs
                class="pd_5"
                :permissions="'activity-logs-for-accounts'"
                :type="'jobs'"
                v-if="
                  props.item.everAdvanceGenerated || props.item.advanceAmount
                "
                :subType="5"
                :assignmentId="props.item._id"
                :_id="props.item.jobData._id"
                :whichId="'Assignment'"
                :id="props.item.assignmentId.toString()"
                :section="'advance'"
              />

              <span class="pd-t-l" v-if="props.item.advanceAmount"
                >{{ props.item.advanceAmount }}
                {{ props.item.customerCurrency }}</span
              >
            </v-layout>

            <AdvanceReadyToPay
              v-if="props.item.assignmentStatus > 1"
              :section="'Accounts'"
              :assignId="props.item._id"
              :totalAdvanceAmountPaid="props.item.totalAdvancePaidAmount"
              :additionalCharges="props.item.totalAdditionalAmount"
              :jobCurrency="props.item.customerCurrency"
              @assignment-list-refresh="refresh()"
            />
            <span
              v-if="
                props.item.assignmentStatus <= 1 && !props.item.advanceAmount
              "
              >N.A.</span
            >
          </td>
          <td v-if="props.item.jobData.isJobActivatedByScript">N.A</td>
          <td v-else>
            <span v-if="props.item.totalAdvancePaidAmount"
              >{{ props.item.totalAdvancePaidAmount }}
              {{ props.item.customerCurrency }}</span
            >
            <span v-else>N.A</span>
          </td>
          <td v-if="props.item.jobData.isJobActivatedByScript">N.A</td>
          <td v-else style=" min-width: 150px; !important">
            <span v-if="props.item.totalMiscellaneousChargeAmount">
              Misc:&nbsp;{{ props.item.totalMiscellaneousChargeAmount }}
              {{ props.item.customerCurrency }}</span
            >
            <br v-if="props.item.totalAdditionalAmount" />
            <span v-if="props.item.totalAdditionalAmount"
              >Driver:&nbsp;
              {{ props.item.totalAdditionalAmount }}
              {{ props.item.customerCurrency }}</span
            ><br v-if="props.item.totalCostMarginAdditionalAmount" />
            <span v-if="props.item.totalCostMarginAdditionalAmount">
              Customer:&nbsp;{{ props.item.totalCostMarginAdditionalAmount }}
              {{ props.item.customerCurrency }}</span
            >

            <additionalChargesPopup
              v-if="props.item.assignmentStatus > 1"
              :isAssign="props.item._id"
              :isCustomerReceiptInitiated="
                props.item.isCustomerReceiptInitiated
              "
              :jobCurrency="props.item.customerCurrency"
              :amountPaidToDriver="props.item.amountPaidToDriver"
              :jobSalesInvoice="props.item.jobData.isSalesInvoiceGenerated"
              :assignSalesInvoice="props.item.isSalesInvoiceGenerated"
            />
            <span
              v-if="
                props.item.assignmentStatus <= 1 &&
                !props.item.totalAdditionalAmount
              "
              >N.A</span
            >
          </td>
          <td>
            <v-layout justify-center>
              <v-flex xs4 v-if="props.item.isReadyForFinalPayment">
                <activityLogs
                  v-if="props.item.isReadyForFinalPayment"
                  class="pd_5 pr-2"
                  :permissions="'activity-logs-for-accounts'"
                  :type="'assignment'"
                  :subType="7"
                  :assignmentId="props.item._id"
                  :_id="props.item.jobData._id"
                  :whichId="'Assignment'"
                  :id="props.item.assignmentId.toString()"
                  :section="'advance'"
                />
              </v-flex>

              <span v-if="!props.item.isReadyForFinalPayment"> N.A </span>
              <span
                v-else-if="
                  !props.item.isPurchaseInvoiceGenerated &&
                  props.item.assignmentStatus != 7 &&
                  props.item.workingCountry !== 'PAK'
                "
              >
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      v-if="
                        props.item.paymentAmount +
                          props.item.totalAdditionalAmount -
                          props.item.totalAdvancePaidAmount -
                          props.item.amountPaidToDriver <
                        0
                      "
                      type="button"
                      color="orange"
                      outline
                      class="pay-ass-btn grey-button"
                      disabled
                    >
                      Adjust
                    </button>
                    <button
                      v-if="
                        props.item.paymentAmount +
                          props.item.totalAdditionalAmount -
                          props.item.totalAdvancePaidAmount -
                          props.item.amountPaidToDriver >
                        0
                      "
                      type="button"
                      color="orange"
                      outline
                      class="pay-ass-btn grey-button pl-2"
                      disabled
                    >
                      {{
                        props.item.ownerType == "Transporter"
                          ? "Pay Transporter"
                          : "Pay Driver"
                      }}
                    </button></template
                  >
                  <span class="tooltip"
                    >Generate Purchase Invoice to pay driver balance</span
                  >
                </v-tooltip></span
              >
              <span
                v-else-if="
                  props.item.totalReadyToPayAmount != 0 &&
                  props.item.workingCountry == 'PAK' &&
                  props.item.assignmentStatus != 7
                "
              >
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      type="button"
                      color="orange"
                      outline
                      class="pay-ass-btn grey-button pl-2"
                      disabled
                    >
                      {{
                        props.item.ownerType == "Transporter"
                          ? "Pay Transporter"
                          : "Pay Driver"
                      }}
                    </button></template
                  >
                  <span class="tooltip">Please clear all advance.</span>
                </v-tooltip></span
              >
              <span
                class="pd_8"
                v-else-if="
                  !props.item.isPurchaseInvoiceGenerated &&
                  props.item.workingCountry === 'PAK' &&
                  props.item.assignmentStatus >= 2 &&
                  props.item.assignmentStatus != -1 &&
                  props.item.assignmentStatus != 4 &&
                  props.item.assignmentStatus != 7
                "
              >
                {{ getAdvanceAmount(props.item) }}</span
              >
              <span
                class="pd_8"
                v-else-if="
                  props.item.isPurchaseInvoiceGenerated &&
                  props.item.assignmentStatus >= 2 &&
                  props.item.assignmentStatus != -1 &&
                  props.item.assignmentStatus != 7 &&
                  props.item.assignmentStatus != 4
                "
              >
                {{ getAdvanceAmount(props.item) }}
              </span>
            </v-layout>

            <v-layout class="pb-1 pt-1" align-center justify-center>
              <DocApproval
                v-if="props.item.assignmentStatus == 7"
                :details="props.item"
                @refresh-list="refresh()"
              />
              <PaymentDone
                :section="'Accounts'"
                :confirmationTitle="'Comments'"
                :details="props.item"
                :cancelBtn="'Cancel'"
                @close="error = 'Kindly collect the gps device'"
                :assignId="props.item._id"
                :PendingAmount="getPendingAmountForProps(props.item)"
                :confirmBtn="'Pay'"
                @refresh-list="refresh()"
              />
              <PendingAmount
                v-if="!props.item.isEntryFailedForFinalPayments"
                @payment-list-refresh="refresh()"
                :invoiceCity="props.item.invoicingCity"
                :invoiceCompany="props.item.invoicingCountry"
                :PendingAmount="getPendingAmountForProps(props.item)"
                :driverCreditDuration="
                  props.item.driverData
                    ? props.item.driverData.driverPaymentDuration
                    : null
                "
                v-permissions="'pending-amount-for-driver-for-accounts'"
                :priceData="props.item"
              />
              <PendingAmountList
                v-if="props.item.isEntryFailedForFinalPayments"
                :type="'rbFailed'"
                :assignId="props.item._id"
                :currency="props.item.customerCurrency"
                :priceData="props.item"
                :PendingAmount="getPendingAmountForProps(props.item)"
                @assignment-list-refresh="refresh()"
              />
            </v-layout>
            <v-layout class="pb-1" align-center justify-center>
              <!-- <PendingAmountList
                v-if="props.item.amountPaidToDriver"
                :type="'Finance'"
                :assignId="props.item._id"
                :currency="props.item.customerCurrency"
                @assignment-list-refresh="refresh()"
              /> -->
            </v-layout>
            <v-layout class="pb-1 pt-1" align-center justify-center>
              <button
                v-if="props.item.isReadyForFinalPayment"
                class="blue-button"
                flat
                prepend-icon
                color="white darken-1"
                @click.stop="goToProof(props.item._id)"
              >
                View Docs
              </button>
              <div
                v-if="
                  (props.item.assignmentStatus == 3 &&
                    props.item.amountPaidToDriver > 0) ||
                  (props.item.assignmentStatus == 4 &&
                    props.item.amountPaidToDriver > 0)
                "
                class="padding-left:5px !important"
              >
                <PendingAmountList
                  v-if="
                    props.item.assignmentStatus == 3 &&
                    !props.item.isEntryFailedForFinalPayments
                  "
                  :type="'Finance'"
                  :assignId="props.item._id"
                  :currency="props.item.customerCurrency"
                  :priceData="props.item"
                  :PendingAmount="getPendingAmountForProps(props.item)"
                  @assignment-list-refresh="refresh()"
                />
                <PendingAmountList
                  v-if="props.item.assignmentStatus == 4"
                  :type="'Finance'"
                  :assignId="props.item._id"
                  :currency="props.item.customerCurrency"
                  :priceData="props.item"
                  :PendingAmount="getPendingAmountForProps(props.item)"
                  @assignment-list-refresh="refresh()"
                />
              </div>
            </v-layout>
          </td>

          <td v-if="props.item.workingCountry == 'PAK'">-</td>
          <td v-else>
            <v-flex v-if="!props.item.workingCountry">-</v-flex>

            <v-flex
              v-else-if="
                !props.item.isPurchaseInvoiceGenerated &&
                !props.item.invoiceNumber &&
                !props.item.isReadyToGeneratePurchaseInvoice &&
                props.item.workingCountry != 'PAK'
              "
            >
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="account-disabled-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    Generate PI
                  </button></template
                >
                <span v-if="!props.item.voucherIdRealBooks" class="tooltip"
                  >Generate PO to generate Purchase invoice.</span
                >
                <span v-if="props.item.voucherIdRealBooks" class="tooltip"
                  >Complete shipment to generate Purchase invoice.</span
                >
              </v-tooltip>
            </v-flex>

            <!--
             <v-flex v-else-if="props.item.workingCountry=='UAE'">
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="assign--disable-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    <v-icon style="color: lightgray; !important" small
                      >settings</v-icon
                    >PI
                  </button></template
                >
                <span class="tooltip"
                  >Generate PO to generate purchase invoice.</span
                >
              </v-tooltip></v-flex
            >
   
           <v-flex
              v-else-if="
                !props.item.isPurchaseInvoiceGenerated &&
                !props.item.invoiceNumber &&
                !props.item.isReadyToGeneratePurchaseInvoice &&
                !props.item.invoicePdfUrl &&
                props.item.VendorWorkingCountry != 'PAK'
              "
            >
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="assign--disable-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    <v-icon style="color: lightgray; !important" small
                      >settings</v-icon
                    >PI
                  </button></template
                >
                <span v-if="!props.item.voucherIdRealBooks" class="tooltip"
                  >Generate SO to generate sales invoice.</span
                >
                <span v-if="props.item.voucherIdRealBooks" class="tooltip"
                  >Complete shipment to generate sales invoice.</span
                >
              </v-tooltip>
            </v-flex> -->

            <v-flex
              v-if="
                props.item.isReadyToGeneratePurchaseInvoice &&
                !props.item.isPurchaseInvoiceGenerated &&
                !props.item.isPurchaseOrderInQueue &&
                props.item.WorkingCountry != 'PAK'
              "
            >
              <div>
                <div v-if="props.item.showPurchaseInvoiceButton">
                  <button
                    @click="
                      openPiGenerate(props.item.assignmentId, props.item._id)
                    "
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    target="_blank"
                    v-if="!props.item.isPurchaseInvoviceBackgroundProcess"
                    class="assign-ass-btn"
                    title="Generate"
                  >
                    Generate PI
                  </button>

                  <div
                    v-if="props.item.isPurchaseInvoviceBackgroundProcess"
                    style="word-break: break-word"
                  >
                    Purchase Invoice can't be generated, Background process is
                    ongoing.
                  </div>
                </div>
                <div v-else>
                  <div>
                    <v-tooltip
                      top
                      class="milestone"
                      content-class="tooltip-op"
                      color="grey"
                    >
                      <template slot="activator">
                        <button
                          class="account-disabled-ass-btn"
                          flat
                          prepend-icon
                          type="button"
                          v-permissions="
                            'generate-downaload-po-pi-for-accounts'
                          "
                          color="grey"
                          disabled
                        >
                          Generate PI
                        </button></template
                      >
                      <span class="tooltip">Please clear all advance</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </v-flex>
            <div v-if="props.item.invoiceNumber == 'NA'">N.A</div>
            <div v-else>
              <v-flex
                v-if="
                  props.item.invoiceNumber &&
                  props.item.isPurchaseInvoiceGenerated &&
                  props.item.WorkingCountry != 'PAK' &&
                  (props.item.assignmentStatus != 1 ||
                    props.item.assignmentStatus != -1)
                "
              >
                <div v-if="props.item.WorkingCountry != 'PAK'">
                  {{ props.item.invoiceNumber }}
                </div>
              </v-flex>

              <v-flex
                v-if="
                  props.item.invoicePdfUrl &&
                  props.item.isPurchaseInvoiceGenerated &&
                  props.item.WorkingCountry != 'PAK'
                "
              >
                <div>
                  <button
                    v-if="props.item.isPurchaseInvoiceDownloadAllowed"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    class="download-ass-btn"
                    flat
                    type="button"
                    prepend-icon
                    :loading="loading"
                    :disabled="loading"
                    title="Download Invoice"
                    color="orange"
                    @click="
                      checkViewInvoice(
                        props.item.invoicePdfUrl,
                        props.item.isDownloadInvocie,
                        props.item.isPurchaseInvoviceBackgroundProcess,
                        props.item.invoiceNumber
                      )
                    "
                  >
                    <v-icon small color="white">download</v-icon>
                  </button>
                  <button
                    v-if="props.item.isPurchaseInvoiceDownloadAllowed"
                    class="download-ass-btn"
                    flat
                    type="button"
                    v-permissions="'pi-download-control'"
                    prepend-icon
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(false, props.item._id)"
                  >
                    Hide
                  </button>
                  <button
                    v-if="!props.item.isPurchaseInvoiceDownloadAllowed"
                    v-permissions="'pi-download-control'"
                    class="download-ass-btn"
                    flat
                    type="button"
                    prepend-icon
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(true, props.item._id)"
                  >
                    Unhide
                  </button>
                </div>
              </v-flex>
              <div
                v-if="
                  props.item.WorkingCountry != 'PAK' &&
                  !props.item.isPurchaseInvoiceGenerated &&
                  props.item.assignmentStatus != -1
                "
                class="pt-1"
              >
                <button
                  v-if="props.item.isPurchaseInvoiceDownloadAllowed"
                  class="download-ass-btn"
                  flat
                  type="button"
                  v-permissions="'pi-download-control'"
                  prepend-icon
                  :loading="loading"
                  :disabled="loading"
                  title="HIDE PI"
                  color="orange"
                  @click="hideUnhidePI(false, props.item._id)"
                >
                  Hide
                </button>
                <button
                  v-if="!props.item.isPurchaseInvoiceDownloadAllowed"
                  v-permissions="'pi-download-control'"
                  class="download-ass-btn"
                  flat
                  type="button"
                  prepend-icon
                  :loading="loading"
                  :disabled="loading"
                  title="UNHIDE PI"
                  color="orange"
                  @click="hideUnhidePI(true, props.item._id)"
                >
                  Unhide
                </button>
              </div>
            </div>
          </td>
          <td v-if="props.item.workingCountry == 'PAK'">-</td>

          <td style=" min-width: 150px; !important" v-else>
            <v-flex v-if="props.item.workingCountry == 'PAK'">-</v-flex>
            <v-flex v-else-if="!props.item.isPurchaseInvoiceGenerated">
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="account-disabled-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    color="grey"
                    disabled
                  >
                    Generate SI
                  </button></template
                >
                <span class="tooltip"
                  >Complete Purchase invoice to generate Sales invoice.</span
                >

                <!-- <span v-if="props.item.jobData.isSalesInvoiceGenerated" class="tooltip"
                  >Job level  Sales Invoice  already  generated.</span
                >
                <span v-if="!props.item.isPurchaseInvoiceGenerated" class="tooltip"
                  >Complete shipment to generate sales invoice.</span
                > -->
              </v-tooltip></v-flex
            >

            <v-flex v-else-if="props.item.jobData.isSalesInvoiceGenerated">
              <div v-if="props.item.jobData.invoiceNumber == 'NA'">N.A</div>
              <div v-else>
                <v-flex
                  v-if="
                    props.item.jobData.invoiceNumber &&
                    props.item.jobData.isSalesInvoiceGenerated &&
                    props.item.workingCountry != 'PAK'
                  "
                >
                  {{ props.item.jobData.invoiceNumber }}
                </v-flex>
                <v-flex
                  v-if="
                    props.item.jobData.invoicePdfUrl &&
                    props.item.jobData.isSalesInvoiceGenerated &&
                    props.item.workingCountry != 'PAK'
                  "
                >
                  <v-layout style="text-align: center">
                    <v-flex style="text-align: end">
                      <button
                        class="download-ass-btn"
                        v-permissions="'generate-downaload-so-si-for-accounts'"
                        type="button"
                        color="orange"
                        title="Download Invoice"
                        :disabled="loading"
                        outline
                        @click="
                          checkViewInvoice2(
                            props.item.jobData.invoicePdfUrl,
                            props.item.isDownloadInvocie,
                            props.item.jobData.isSaleInvoviceBackgroundProcess,
                            props.item.jobData.invoiceNumber,
                            $event
                          )
                        "
                      >
                        <v-icon color="white" small>download</v-icon>
                      </button>
                    </v-flex>
                    <v-flex style="text-align: start; padding-left: 3px">
                      <SendSI
                        :jobId="props.item.jobId"
                        :email="props.item.userData.email"
                        :invoiceUrl="props.item.salesInvoicePdfUrl"
                        :workingCountry="props.item.workingCountry"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </div>
            </v-flex>

            <v-flex
              v-if="
                !props.item.isSalesInvoiceGenerated &&
                !props.item.jobData.isSalesInvoiceGenerated &&
                props.item.isPurchaseInvoiceGenerated &&
                props.item.workingCountry != 'PAK' &&
                !props.item.isSaleOrderInQueue
              "
            >
              <!-- <div class="subheading muted">Generate Sales Invoice</div>
              <div class="heading"> -->
              <button
                v-permissions="'generate-downaload-so-si-for-accounts'"
                @click="
                  openSiPopup(props.item.userData, props.item._id, props.item)
                "
                type="button"
                v-if="!props.item.isSaleInvoviceBackgroundProcess"
                class="account-ass-btn"
                :disabled="loading"
                color="orange"
                outline
              >
                Generate SI
              </button>

              <!-- </div> -->
            </v-flex>
            <div
              v-if="
                props.item.isSaleInvoviceBackgroundProcess &&
                !props.item.jobData.isSalesInvoiceGenerated
              "
              style="word-break: break-word"
            >
              Background process is ongoing.
            </div>

            <v-flex
              v-if="
                props.item.salesInvoiceNumber &&
                props.item.isSalesInvoiceGenerated &&
                !props.item.jobData.isSalesInvoiceGenerated &&
                props.item.workingCountry != 'PAK'
              "
            >
              {{ props.item.salesInvoiceNumber }}
            </v-flex>
            <v-flex
              v-if="
                props.item.salesInvoicePdfUrl &&
                props.item.isSalesInvoiceGenerated &&
                !props.item.jobData.isSalesInvoiceGenerated &&
                props.item.workingCountry != 'PAK'
              "
            >
              <v-layout style="text-align: center">
                <v-flex style="text-align: end">
                  <button
                    class="download-ass-btn"
                    v-permissions="'generate-downaload-so-si-for-accounts'"
                    type="button"
                    color="orange"
                    title="Download Invoice"
                    :disabled="loading"
                    outline
                    @click="
                      checkViewInvoice2(
                        props.item.salesInvoicePdfUrl,
                        props.item.isDownloadInvocie,
                        props.item.isSaleInvoviceBackgroundProcess,
                        props.item.salesInvoiceNumber,
                        $event
                      )
                    "
                  >
                    <v-icon color="white" small>download</v-icon>
                  </button>
                </v-flex>
                <v-flex style="text-align: start; padding-left: 3px">
                  <SendSI
                    :jobId="props.item.jobId"
                    :email="props.item.userData.email"
                    :invoiceUrl="props.item.salesInvoicePdfUrl"
                    :workingCountry="props.item.workingCountry"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </td>
          <td>
            <div v-if="props.item.LastCommentTime">
              <v-tooltip
                left
                class="milestone"
                content-class="tooltip-op-comment"
                color="orange"
              >
                <template slot="activator">
                  {{ getTime4(props.item.LastCommentTime.updatedAt) }}</template
                >
                <span
                  class="tooltip"
                  style="word-break: break-all !important"
                  >{{
                    props.item.LastCommentTime.comment != " "
                      ? props.item.LastCommentTime.comment
                      : "N.A"
                  }}</span
                >
              </v-tooltip>
            </div>
            <SendComments
              :userId="props.item.userId"
              :userName="props.item.customer"
              :detail="props.item"
              :type="3"
              :assignId="props.item._id"
              v-on:pagination-load="refreshOnComment"
              :section="'assignments'"
              :id="props.item.jobId"
            />
          </td>

          <td>
            <AdvanceReadyToPay
              v-if="
                props.item.assignmentStatus > 1 &&
                props.item.assignmentStatus != 4
              "
              :section="'Pay'"
              :isVisible="props.item.totalReadyToPayAmount"
              :assignId="props.item._id"
              :advancePaid="props.item.totalAdvancePaidAmount"
              :additionalCharges="props.item.totalAdditionalAmount"
              :jobCurrency="props.item.customerCurrency"
              @assignment-list-refresh="refresh()"
            />
            <button
              @click="
                goToDoc(
                  props.item.jobId,
                  props.item.assignmentId,
                  props.item._id
                )
              "
              class="doc-ass-btn"
              outline
              color="orange"
              type="button"
            >
              Documents
            </button>
          </td>
        </template>
      </v-data-table>
      <success-dialog
        :content="x.message"
        :show="x.success"
        :heading="x.heading"
        :onclose="closeMessage"
      />
      <pagination
        :totalPages="totalPages"
        :componentKey="componentKey"
        :pages="this.pages"
        @pageNo="page"
        :type="0"
        :url="paginationURL"
        :loading="loading"
        :callback="callback"
        :action="`advance`"
        v-on:pagination-load="checkLoading"
      />
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >

      <confirmation-dialog
        :content="`Are you sure to remove transporter from this assignment?`"
        :toShow="confirmationDialog.removeTransporterAssignment"
        :onClose="() => remove()"
        confirmTitle="Unassign"
        :loading="loading"
        :error="error"
        :onConfirm="() => removeTransporterAssignment(this.assignmentData)"
      />
      <confirmation-dialog
        :content="`Are you sure to remove ${
          this.assignmentData.driverData
            ? this.assignmentData.driverData.name
            : ''
        } from this assignment?`"
        :toShow="confirmationDialog.removeDriverAssignment"
        :onClose="removeDriver"
        confirmTitle="Unassign"
        :loading="loading"
        :error="error"
        :onConfirm="() => removeDriverAssignment(this.assignmentData)"
      />
    </v-card-text>
    <v-dialog
      v-model="invoiceDialog"
      persistent
      :max-width="500"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title>{{ headerVatPopup }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="invoiceDialog = false"
              :disabled="loading"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <div style="text-align: center">
            <div class="pb-4">
              <img
                src="../../../assets/ConfirmationPi.svg"
                height="350"
                width="500"
                class="spMargin"
              />
            </div>
            <div>{{ messageVat }}</div>
          </div>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none; !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="confirmationPiGenerate('Without VaT')"
              >No</v-btn
            >
            <v-btn
              v-if="!vatInfo"
              color="grey"
              @click="invoiceDialog = false"
              text
              >Cancel</v-btn
            >
            <v-btn
              v-if="!vatInfo"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="confirmationPiGenerate('With VaT')"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRetry"
      persistent
      :max-width="650"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #f5f5f5; color: black" flat>
          <v-toolbar-title> {{ headingRetryPopup }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialogRetry = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4"> {{ messageRetryPopup }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              medium
              style="text-transform: none; !important"
              darken-1
              :disabled="loading"
              text
              @click="dialogRetry = false"
              >Cancel</v-btn
            >
            <v-btn
              v-if="headingRetryPopup == 'Confirmation'"
              medium
              style="text-transform: none; !important"
              color="orange white--text "
              darken-1
              @click="retryAllAdvanceOrFinalPayment('advance')"
              :disabled="loading"
              text
              >Yes,Pay</v-btn
            >
            <v-btn
              v-else
              medium
              style="text-transform: none; !important"
              color="orange white--text "
              darken-1
              @click="retryAllAdvanceOrFinalPayment('final')"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPi"
      persistent
      :max-width="650"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title> Final Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialogPi = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4">
            {{ ConfirmMessageVat }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none; !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="dialogPi = false"
              >No</v-btn
            >
            <v-btn
              v-if="!FinalvatApply"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber('No'), (vatAmount = 0)"
              :disabled="loading"
              text
              >Yes</v-btn
            >

            <v-btn
              v-if="FinalvatApply"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber('Yes')"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog1"
      persistent
      :max-width="700"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title>Generate Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="closeBankInfo()"
              :disabled="loading"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text>
            <div>
              <!-- <img
                src="../../assets/Character-sitting.png"
                height="300"
                width="300"
              /> -->
            </div>

            <div>
              <div class="fonthead">
                Are you sure want to generate invoice number?
              </div>
              <v-layout class="pt-2">
                <v-flex xs6 md6 pr-3>
                  <v-autocomplete
                    placeholder=" Please Choose Bank"
                    :items="bankInfo"
                    class="pt-0 currencyTitle"
                    v-model="bank"
                    item-text="text"
                    item-value="value"
                    @change="setCustomerValues($event)"
                  />
                </v-flex>
                <v-flex xs6 md6 v-if="bank.length">
                  <div class="sub-heading muted">
                    Account Number:
                    <span style="color: black" class="heading">{{
                      accountNo
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>

              <v-layout class="pb-3" v-if="bank.length">
                <v-flex xs6 md6 pr-3>
                  <div class="sub-heading muted">
                    IBAN NO:
                    <span
                      style="color: black"
                      class="heading word___Break__all"
                      >{{ ibanNo }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Branch Code :
                    <span style="color: black" class="heading">{{
                      branchCode
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3" v-if="bank.length">
                <v-flex xs6 md6 pr-3>
                  <div class="sub-heading muted">
                    Swift Code :
                    <span style="color: black" class="heading">{{
                      swiftCode
                    }}</span>
                  </div>
                </v-flex>
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Beneficiary Name :
                    <span style="color: black" class="heading">{{
                      beneficiaryName
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-2" style="justify-content: right">
            <v-btn
              medium
              style="text-transform: none; !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="closeBankInfo()"
              >No</v-btn
            >
            <v-btn
              medium
              style="text-transform: none; !important"
              color="green white--text "
              darken-1
              @click="generateInvoiceNumber2()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import { accountAdvanceList } from "@/constants/datatable-headers.js";
import moment from "moment";
import PendingAmount from "../Popups/PendingAmount.vue";
import DocApproval from "../Popups/DocApproval.vue";
import PaymentDone from "../Popups/PaymentDone.vue";
import EditPendingAmount from "../Popups/EditPendingAmount";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import SendSI from "../Popups/SendSI";
import confirmationPopup from "@/components/Pop-ups/confirmationPopup.vue";
import CancellationPopup from "@/components/Pop-ups/CancellationPopup.vue";

import AddProofDoc from "@/components/Pop-ups/AddProofDoc";
import Loading from "vue-loading-overlay";
import {
  StorageKeys,
  JobListTypes,
  TruckTypes,
  JobTypes,
  SeaTypes,
  AirTypes,
  TruckArray,
  AssignmentStatusList,
  AdvanceStatusaccountsList,
} from "@/constants/constants";
import activityLogs from "../../Common/activityLogs.vue";
import AssignmentInvoicePopup from "./AssignmentInvoicePopup";
import MultipleAssignmentPopup from "./MultipleAssignmentPopup";
import CustomerDetailPopUp from "../../Customer/CustomerDetailPopUp.vue";
import SendComments from "@/components/Pop-ups/SendComments";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
import ViewDetails from "@/components/Pop-ups/ViewDetails";
import additionalChargesPopup from "@/components/JobDocuments/additionalChargesPopup";
import generateAdvancePopup from "@/components/JobDocuments/generateAdvancePopup";
import ConfirmationDialog from "../../Common/ConfirmationDialog.vue";
import downloadMixin from "@/mixins/downloadMixin";
import PendingAmountList from "../Popups/PendingAmountList";
import AdvancePaid from "../Popups/AdvancePaid.vue";
import AdvanceReadyToPay from "../Popups/AdvanceReadyToPay.vue";
import { EventBus } from "../../../event-bus.js";
import pagination from "@/components/Pagination/pagination";
let momentTz = require("moment-timezone");
export default {
  mixins: [downloadMixin],
  created() {
    this.getSearchList();
    this.JobTypes = JobListTypes;
    this.subTruck = TruckTypes;
    this.TruckTypesList = TruckArray;

    this.$eventBus.$on("accounts-purchase-invoice", (data) => {
      if (data.isPurchaseInvoiceForAdditionalCharges) {
        this.items.forEach((e) => {
          if (data.socketPurchInvoiceData.assignmentId === e.assignmentId) {
            console.log(e);

            // console.log(data.socketPurchInvoiceData);
            // console.log(Object.keys(data.socketPurchInvoiceData));
            // console.log(Object.keys(e));
            // for (let x in data.socketPurchInvoiceData) {
            //   console.log("trrr", x);
            // }
            // data.socketPurchInvoiceData.totalReadyToPayAmount = "3000";
            for (const key in e)
              if (key in data.socketPurchInvoiceData)
                e[key] = data.socketPurchInvoiceData[key];
            // console.log(data.socketPurchInvoiceData);
          }

          return e;
        });
      } else {
        this.refresh();
      }
    });
    this.$eventBus.$on("accounts-purchase-invoice2", (data) => {
      console.log(data, "accounts-purchase-invoice2");
      // if (data.isSalesInvoiceForAdditionalCharges) {
      //   this.items.forEach((e) => {
      //     if (data.socketSalesInvoiceData.assignmentId === e.assignmentId) {

      //       for (const key in e)
      //         if (key in data.socketSalesInvoiceData)
      //           e[key] = data.socketSalesInvoiceData[key];

      //     }

      //     return e;
      //   });
      // } else {
      setTimeout(() => {
        this.refresh();
      }, 1000);
      // }
    });
    this.$eventBus.$on("accounts-purchase-order", (data) => {
      if (!data.isPurchaseOrderForAdditionalCharges) {
        this.refresh();
      }
    });
    this.$eventBus.$on("accounts-sales-order", (data) => {
      if (!data.isSalesOrderForAdditionalCharge) {
        this.refresh();
      }
    });
    this.$eventBus.$on("accounts-sales-invoice", (data) => {
      this.refresh();
    });

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.loading = true;
      if (this.$route.name == "Advance") {
        if (this.$route.query) {
          //   this.search = "";
          //   this.searchTypes = "";
          //   this.$router.push(`/accounts/advance`).catch(() => {});
          // } else {
          this.search = "";
          this.searchTypes = "";
          this.generateInvoiceCount = 0;
          this.showCheckbox = false;
          this.paginationURL = `/dmsAdmin/accountAssignmentView?workingCountry=${this.workingCountry}&isSearch=true`;
          this.getListCount();
        }
      }
    });

    if (localStorage.getItem("workingCountries")) {
      if (this.$route.query && this.$route.query.type) {
        this.searchTypes = { status: "Assignment ID", abbr: "assignmentId" };
        this.search = this.$route.query.type;

        let workingCountry = [];
        workingCountry.push(this.$route.query.country);
        this.workingCountry = JSON.stringify(workingCountry);
        this.paginationURL = `/dmsAdmin/accountAssignmentView?workingCountry=${this.workingCountry}&isSearch=true&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
        this.getListCount();
        return;
      }

      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);

      this.paginationURL = `/dmsAdmin/accountAssignmentView?workingCountry=${this.workingCountry}&isSearch=true`;
      this.getListCount();
    }
    this.$eventBus.$on("finance", (data) => {
      if (this.$route.name === "Advance") {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          this.workingCountry = JSON.stringify(workingCountry);
        }
        if (this.workingCountry.includes(data)) {
          this.componentKey = this.componentKey + 1;
          this.getListCount();
        }
      }
    });

    this.$eventBus.$on("job-details-purchase-invoice", (data) => {
      if (this.workingCountry.includes(data)) {
        this.componentKey = this.componentKey + 1;
      }
    });
    this.$eventBus.$on("togglePurchaseInvoiceSwitch", (data) => {
      if (this.workingCountry.includes(data)) {
        this.componentKey = this.componentKey + 1;
      }
    });

    // this.$eventBus.$on("finance", (data) => {
    //   if (this.$route.name === "Advance") {
    //     if (localStorage.getItem("workingCountries")) {
    //       this.workingCountries = JSON.parse(
    //         localStorage.getItem("workingCountries")
    //       );
    //       let workingCountry = [];
    //       this.workingCountries.forEach((key, index) => {
    //         if (key.isSelected) {
    //           workingCountry.push(key.value);
    //         }
    //       });
    //       this.workingCountry = JSON.stringify(workingCountry);
    //     }
    //     if (this.workingCountry.includes(data.workingCountry)) {
    //       this.componentKey = this.componentKey + 1;
    //       this.getListCount();
    //     }
    //   }
    // });
    this.$eventBus.$on("assignments", (data) => {
      if (this.$route.name === "Advance") {
        if (this.workingCountry.includes(data.workingCountry)) {
          this.componentKey = this.componentKey + 1;
          this.getListCount();
        }
      }
    });

    // this.$eventBus.$on("job-details-purchase-invoice", (data) => {

    //   this.items.forEach((e) => {
    //     if (data.assignmentId === e.assignmentId) {
    //

    //       e.totalAdditionalAmount = data.totalAdditionalAmount;
    //     }

    //     return e;
    //   });
    //   if (this.workingCountry.includes(data)) {
    //     this.componentKey = this.componentKey + 1;
    //   }
    // });

    this.$eventBus.$on("additionalchargeAdd", (data) => {
      if (this.$route.name === "Advance") {
        // if (this.workingCountry.includes(data.workingCountry)) {
        //   this.componentKey = this.componentKey + 1;
        //   this.getListCount();
        // }
        if (data.assignmentId) {
          this.items.forEach((e) => {
            if (data.assignmentId === e.assignmentId) {
              e.totalAdditionalAmount =
                data.totalAdditionalAmount > 0 ? data.totalAdditionalAmount : 0;
              e.totalCostMarginAdditionalAmount =
                data.totalCostMarginAmount > 0 ? data.totalCostMarginAmount : 0;
            }

            return e;
          });
        } else {
          this.componentKey = this.componentKey + 1;
          this.getListCount();
        }
      }
    });
  },

  components: {
    pagination,
    PendingAmount,
    SendSI,
    PaymentDone,
    EditPendingAmount,
    AdvancePaid,
    PendingAmountList,
    ProofOfDelivery,
    AssignmentInvoicePopup,
    AdvanceReadyToPay,
    activityLogs,
    CustomerDetailPopUp,
    confirmationPopup,
    CancellationPopup,
    SendComments,
    DocApproval,
    additionalChargesPopup,
    MultipleAssignmentPopup,
    generateAdvancePopup,
    GenerateAdvance,
    ConfirmationDialog,
    ViewDetails,
    AddProofDoc,
    Loading,
    SuccessDialog,
  },

  data() {
    return {
      active: "0",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },

      componentKey: 0,
      dialogPi: false,
      dialogRetry: false,
      SelectAssignmentStatus: null,
      showCheckbox: false,
      id: null,
      airTypes: AirTypes,
      seaTypes: SeaTypes,
      pagination: {},
      TruckTypesList: [],
      salesAssignId: null,
      invoiceDialog: false,
      loading: true,
      paginationURL: ``,
      id: "",
      waterMark: "Select a Range",
      dateRange2: null,
      advanceRequested: 0,
      advanceRequestedAmount: 0,
      advanceReadyToPayAmount: 0,
      readyToPayAmount: 0,
      finalReadyToPay: 0,
      failedAdvanceAmount: 0,
      failedAdvanceAmountCount: 0,

      totalPendingFinalPayAmount: 0,
      totalPendingFinalPayCount: 0,
      finalPaymentRbFailedAmount: 0,
      finalPaymentRbFailedCount: 0,
      totalFinalFailedAmount: 0,
      totalFinalFailedCount: 0,
      internalFailedAmount: 0,
      internalFailedCount: 0,
      rbretryButtonShow: "",
      rbretryButtonShowFinal: "",
      advancePaidTotalAmount: 0,
      advanceReadyToPay: 0,
      advancePaidAmount: 0,
      invoicingCity: "",
      invoicingCountry: "",
      bankInfo: [],
      bankName: [],
      bank: "",
      beneficiaryName: "",
      branchCode: "",
      ibanNo: "",
      swiftCode: "",
      accountNo: "",
      bankId: "",
      dialog1: false,
      dateRange: { startDate: "", endDate: "" },
      startDate: "",
      endDate: "",
      finalPayment: 0,

      readyToPay: 0,
      readyToPayAmount: 0,
      workingCountry: null,
      assignmentData: {},
      driverDetailHeaders: {
        id: "ID",
        name: "Name",
        phone: "Phone No.",
      },
      transporterDetailHeaders: {
        id: "ID",
        name: "Name",
        email: "Email",
        phone: "Phone No.",
      },
      selectCountryDialog: false,
      error: null,
      JobTypes: [],
      confirmationDialog: {
        cancelAssignment: false,
        makePayment: false,
        completeAssignment: false,
        removeDriverAssignment: false,
        removeTransporterAssignment: false,
      },
      totalPages: 0,
      items: [],
      changeTab: "advanceDetail",
      headers: accountAdvanceList,
      advanceStatusList: [],
      pages: 1,
      rules: {
        required: (value) => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
      statusList: [],
      statusListInProgress: [],

      statusListCompleted: [],
      selectedCustomerDataInvoice: null,
      assignStatus: "",
      finalPaymentStatus: false,
      advanceStatus: "",
      driverSearch: "",
      selected: [],
      selectedids: [],
      selectednames: [],
      headerVatPopup: "",
      messageVat: "",
      ConfirmMessageVat: "",
      headingRetryPopup: "",
      messageRetryPopup: "",
      FinalvatApply: false,
      vatInfo: null,
      vatAmount: null,
      driverSearchTypes: "",
      subJobType: "",
      searchTypes: "",
      search: "",
      subTruck: [],
      totalPages: 0,
      totalJob: 0,
      x: {
        message: "The advance payment has been successfully done",
        loading: true,
        success: false,
        heading: "Advance Payment Update",
        menu: false,

        error: null,
        missingField: null,
      },
      finalReadyToPay: 0,
      generateInvoiceCount: 0,

      subTruckType: "",
      searchItems: [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        { status: "Driver ID", abbr: "driverId" },
        {
          status: "Driver Name",
          abbr: "driverName",
        },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Tranporter ID", abbr: "transporterId" },
        {
          status: "Transporter Name",
          abbr: "Transporter Name",
        },
      ],
      driverSearchItems: [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        { status: "Driver ID", abbr: "driverId" },
        {
          status: "Driver Name",
          abbr: "driverName",
        },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Tranporter ID", abbr: "transporterId" },
        {
          status: "Transporter Name",
          abbr: "Transporter Name",
        },
      ],
    };
  },
  watch: {
    count1(val) {
      this.generateInvoiceCount = val;
    },

    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
        }
        this.searchData(null, val);
        this.getListCount();
      } else {
        this.startDate = "";
        this.endDate = "";

        this.searchData(null, val);
        this.getListCount();
      }
    },
    "$route.query.type"() {
      if (this.$route.query && this.$route.query.type) {
        this.searchTypes = { status: "Assignment ID", abbr: "assignmentId" };
        this.search = this.$route.query.type;

        let workingCountry = [];
        workingCountry.push(this.$route.query.country);
        this.workingCountry = JSON.stringify(workingCountry);
        this.paginationURL = `/dmsAdmin/accountAssignmentView?workingCountry=${this.workingCountry}&isSearch=true&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
        this.getListCount();
        return;
      }
      // } else {
      //   this.paginationURL = `/dmsAdmin/accountAssignmentView?workingCountry=${this.workingCountry}&isSearch=true`;
      //   this.getListCount();
      // }
    },

    pagination(val) {
      let temp_url = "";
      if (val["sortBy"] === "createdDate") {
        if (val["descending"] === true) {
          temp_url = "&toSort=date&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=date&sortBy=descending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (val["sortBy"] === "quote") {
        if (val["descending"] === true) {
          temp_url = "&toSort=price&sortBy=descending";
          this.updateQueryStringParameter("toSort", "price");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=price&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "price");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (!val["sortBy"]) {
        // this.removeParam(["toSort", "sortBy"]);
      }
      this.updateQueryStringParameter("isSearch", "true");
      deep: true;
    },
  },
  methods: {
    setCustomerValues(val) {
      var data = this.bankInfo.filter((v) => {
        if (v.value === val) return v;
      });
      (this.beneficiaryName = data[0].beneficiaryName),
        (this.branchCode = data[0].branchCode),
        (this.accountNo = data[0].accountNo),
        (this.ibanNo = data[0].ibanNo),
        (this.bankId = data[0].bankId),
        (this.swiftCode = data[0].swiftCode);
      // this.beneficiaryName = data[0].beneficiaryName;
    },
    hideUnhidePI(val, id) {
      this.loading = true;

      let url = "/assignments/purchase-invoice-switch";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentId: id.toString(),
        isPurchaseInvoiceDownloadAllowed: val,
      };

      this.axios.patch(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.refresh();
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    confirmationPiGenerate(data) {
      if (data == "With VaT") {
        this.ConfirmMessageVat = "Are you sure you want to apply VAT?";
        this.dialogPi = true;
        this.FinalvatApply = true;
      } else {
        this.FinalvatApply = false;
        this.ConfirmMessageVat = "Are you sure you don't want to apply VAT?";
        this.dialogPi = true;
      }
    },

    checkDataForSIGeneration(
      piGenerated,
      piPdfUrl,
      assignStatus,
      paymentAmount,
      totalAdditionalAmount,
      totalAdvancePaidAmount,
      amountPaidToDriver,
      country,
      rbEntryFailed
    ) {
      if (piPdfUrl && country != "PAK" && piGenerated) {
        if (
          this.searchTypes.abbr == "driverId" ||
          this.searchTypes.abbr == "transporterId"
        ) {
          if (
            paymentAmount +
              totalAdditionalAmount -
              totalAdvancePaidAmount -
              amountPaidToDriver <
              0 ||
            assignStatus !== 3 ||
            rbEntryFailed
          ) {
            return true;
          }
        } else {
          return false;
        }
      } else if (country == "PAK") {
        if (
          this.searchTypes.abbr == "driverId" ||
          this.searchTypes.abbr == "transporterId"
        ) {
          if (
            paymentAmount +
              totalAdditionalAmount -
              totalAdvancePaidAmount -
              amountPaidToDriver <
              0 ||
            assignStatus !== 3
          ) {
            return true;
          }
        } else if (
          this.searchTypes.abbr == "jobId" ||
          this.searchTypes.abbr == "assignmentId"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    closeBankInfo() {
      this.dialog1 = false;
      this.invoiceDay = null;
      this.isMultipleInvoiceFailed = false;
      this.bankInfo = [];
      this.bank = "";
      (this.beneficiaryName = ""),
        (this.branchCode = ""),
        (this.accountNo = ""),
        (this.ibanNo = ""),
        (this.bankId = ""),
        (this.swiftCode = "");
    },

    async checkViewInvoice(
      url,
      isDownloadInvocie,
      isPurchaseInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.loading = true;
      this.operationName = "generate-downaload-po-pi-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        if (isPurchaseInvoviceBackgroundProcess) {
          this.loading = false;
          this.error =
            "Purchase Invoice can't be downloaded, background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Purchase Invoice" + " " + invoiceNumber);
      } else {
        this.loading = false;
        return;
      }
    },

    async checkViewInvoice2(
      url,
      isDownloadInvocie,
      isPurchaseInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.loading = true;
      this.operationName = "generate-downaload-po-pi-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        if (isPurchaseInvoviceBackgroundProcess) {
          this.loading = false;
          this.error =
            "Purchase Invoice can't be downloaded, background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Sales Invoice" + " " + invoiceNumber);
      } else {
        this.loading = false;
        return;
      }
    },

    async generateInvoiceNumber(vatType) {
      this.loading = true;
      this.operationName = "generate-downaload-po-pi-for-accounts";

      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        // this.loading = true;

        let url = "/dmsAdmin/generatePurchaseInvoice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { assignmentId: this.id.toString() };
        if (this.vatAmount > 0 && vatType == "Yes") {
          body.vatAmount = this.vatAmount;
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.invoiceDialog = false;
            this.dialogPi = false;
            this.loading = false;
            this.refresh();
          },
          (error) => {
            this.dialogPi = false;
            this.loading = false;
            this.error = error.response.data.message;
            this.invoiceDialog = false;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },

    async generateInvoiceNumber2() {
      this.loading = true;
      this.operationName = "generate-downaload-so-si-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (!this.bank) {
        this.loading = false;
        this.error = "Please Choose Bank Before Generate Invoice";
        return;
      }
      if (y) {
        this.loading = true;

        let url = "/dmsAdmin/generate-assignment-invoice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          assignmentIds: [this.salesAssignId],
          bankId: this.bankId,
          type: "INVOICE_PER_ASSIGNMENT",
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            //this.items = response.data.data;
            this.loading = false;
            this.dialog1 = false;

            this.componentKey = this.componentKey + 1;
            // this.x.message = "Drivers notified";
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },

    count1(val) {
      this.generateInvoiceCount = val;
    },

    openPiGenerate(assignmentId, assId) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        assignmentId: assId,
      };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/get-vat-details`, body, config)
        .then((response) => {
          if (response.data.data.amount > 0) {
            this.vatInfo = true;
            this.invoiceDialog = true;
            this.headerVatPopup = "Generate Purchase Invoice With VAT";
            this.messageVat = ` Do you want to generate Purchase Invoice with  VAT ${
              response.data.data.vatPercent
            } % (${response.data.data.amount.toFixed(2)} ${
              response.data.data.currency
            }) ?`;
            this.vatAmount = response.data.data.amount;
          } else {
            this.invoiceDialog = true;
            this.vatInfo = false;

            this.headerVatPopup = "Generate Purchase Invoice";
            this.messageVat = ` Do you want to generate Purchase Invoice ?`;
          }
        });

      this.cancelloading = false;
      this.loading = false;
      this.id = assignmentId;
    },
    openSiPopup(userData, id, assData) {
      this.SelectAssignmentStatus = assData;
      this.dialog1 = true;
      this.userDetail = userData;
      this.fetchBank();
      this.salesAssignId = id;
    },

    //fectch Bank details for Sales Invoice

    fetchBank() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        invoicingCity: {
          code: this.SelectAssignmentStatus.invoicingCity,
        },
        invoicingCountry: {
          code: this.SelectAssignmentStatus.invoicingCountry,
        },
      };
      let { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/accounts/getBankDetails`, body, config).then(
        (response) => {
          const { data = null } = response.data;
          data.forEach((element) => {
            this.bankInfo.push({
              text: element.bankName,
              value: element._id,
              beneficiaryName: element.beneficiaryName,
              branchCode: element.branchCode,
              accountNo: element.accountNo,
              ibanNo: element.ibanNo,
              bankId: element._id,
              swiftCode: element.swiftCode,

              // companyName: element.companyName,
              // companyVatNumber: element.companyVatNumber,
              // address: element.address
              //   ? element.address.replaceAll("\n", ", ")
              //   : "",
              // invoicingCity: element.invoicingCity,
              // invoicingCountry: element.invoicingCountry,
              // selectedIndustry: element.selectedIndustry,
            });
          });

          // this.bankName = response.data.data[2].bankName;
        },
        (error) => {
          this.error = "Something went wrong. Please try again later!";
          this.loading = false;
        }
      );
    },

    refreshOnComment() {
      this.componentKey++;
    },

    resetInvoiceIds() {
      this.selectedids = [];
      this.selectednames = [];
      this.generateInvoiceCount = 0;
    },

    checkSelected(select, id, e) {
      if (select && !this.selectedids.includes(id)) {
        this.generateInvoiceCount = this.generateInvoiceCount + 1;
        this.$emit("count", this.generateInvoiceCount);
        this.selectedids.push(id);
        this.selectednames.push(id);
      }
      if (!select && this.selectedids.includes(id)) {
        this.generateInvoiceCount = this.generateInvoiceCount - 1;
        this.$emit("count", this.generateInvoiceCount);
        this.selectedids.splice(this.selectedids.indexOf(id), 1);
        this.selectednames.splice(this.selectednames.indexOf(id), 1);
      }

      this.selectedCustomerDataInvoice = this.items[0];
    },

    getAdvanceAmount(data) {
      let pendingAmount = this.getPendingAmountForProps(data, true);
      return `${pendingAmount}  ${data.customerCurrency}`;
    },
    getPendingAmountForProps(data, isDecimal = false) {
      let amountPaidToDriver = data.amountPaidToDriver || 0;
      let adjustAmount = data.amountAdjustedFromThisAssignment || 0;
      let totalAdvance =
        data.paymentAmount -
        (data.totalAdvancePaidAmount - adjustAmount) -
        amountPaidToDriver +
        data.totalAdditionalAmount;

      if (isDecimal) return totalAdvance.toFixed(2);
      else return Number(totalAdvance.toFixed(2));
    },
    goToProof(_id) {
      this.$router.push(`/documents/${_id}?type=Driver_Proof`);
    },
    getSearchList() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      const url = `${this.constants.apiUrl}/dmsAdmin/searchListArray`;
      this.axios.get(url, config).then((response) => {
        this.statusListInProgress = response.data.data.assignStatusInProgress;
        this.statusListCompleted = response.data.data.assignStatusCompleted;

        this.statusList = this.statusListInProgress.concat(
          this.statusListCompleted
        );
        // this.statusList.push({
        //   text: "RB Entry Failed",
        //   value: "RB_Entry_Failed",
        //   wantToDisplay: true,
        // });
        this.advanceStatusList = response.data.data.AdvanceStatusAccountsList;
      });
    },
    goToDoc(jobId, assignId, _id) {
      this.$router.push(`/documents/${_id}`);
    },
    async check(jobId) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$router.push(`/jobs/${jobId}`);
      } else {
        return;
      }
    },
    getText(arr = [], val) {
      let finalEl = null;
      finalEl = arr.find((el) => el.value == val);
      return finalEl ? finalEl.text : "NA";
    },
    async callback({ jobInfo }) {
      this.items = jobInfo;
      this.items.forEach((e) => {
        e.selected = false;

        if (this.selectedids.includes(e._id)) {
          e.selected = true;
        }
      });
      this.pagination.rowsPerPage = 15;
      window.scrollTo(0, 0);
      this.loading = false;
    },
    refresh() {
      this.componentKey = this.componentKey + 1;
    },
    refreshMultiple() {
      this.selectedids = [];
      this.selectednames = [];
      this.generateInvoiceCount = 0;
      this.componentKey = this.componentKey + 1;
    },

    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    // getListCount() {
    //   let v = {};
    //   this.paginationLoading = true;
    //   let Url = "/dmsAdmin/accountAssignmentDashboard";
    //       case -1:
    //         return "Job Cancelled";
    //       default:
    //         return "Unknown";
    //     }
    //   },
    getListCount() {
      let v = {};
      this.loading = true;
      let Url = "/dmsAdmin/accountAssignmentDashboard";

      let temp_url = this.paginationURL;
      Url = "/dmsAdmin/accountAssignmentDashboard?" + temp_url.split("?")[1];
      let body = {};
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;
      this.advanceRequested = 0;
      this.advanceRequestedAmount = 0;
      this.advanceReadyToPayAmount = 0;
      this.failedAdvanceAmount = 0;
      this.failedAdvanceAmountCount = 0;
      this.totalPendingFinalPayAmount = 0;
      this.totalPendingFinalPayCount = 0;
      this.finalPaymentRbFailedAmount = 0;
      this.finalPaymentRbFailedCount = 0;
      (this.totalFinalFailedAmount = 0),
        (this.totalFinalFailedCount = 0),
        (this.internalFailedAmount = 0),
        (this.internalFailedCount = 0),
        (this.advancePaidTotalAmount = 0);
      this.advanceReadyToPay = 0;
      this.advancePaidAmount = 0;
      this.finalPayment = 0;
      this.readyToPay = 0;
      this.readyToPayAmount = 0;
      this.finalReadyToPay = 0;
      this.totalPages = 0;
      this.totalJob = 0;
      v = this.axios.get(this.constants.apiUrl + Url, body).then(
        (response) => {
          this.loading = false;

          this.jobCount = response.data.data;
          let countData = this.jobCount;
          if (!this.search.trim().length) {
            localStorage.removeItem("searchAdvance");
          }
          if (!this.driverSearch.trim().length) {
            localStorage.removeItem("searchAdvanceDriver");
          }
          this.advanceRequested = countData.advanceRequested;
          this.advanceRequestedAmount = countData.advanceRequestedAmount;
          this.advanceReadyToPayAmount = countData.advanceReadyToPayAmount;
          this.failedAdvanceAmount = countData.advanceRbFailedTotalAmount;
          this.failedAdvanceAmountCount = countData.advanceRbFailedAmount;
          this.totalPendingFinalPayAmount =
            countData.paymentPendingEntriesAmountTotal;
          this.totalPendingFinalPayCount = countData.totalPendingPaymentEntries;
          this.finalPaymentRbFailedAmount =
            countData.finalPaymentRbFailedAmount;
          this.finalPaymentRbFailedCount =
            countData.finalPaymentRbFailedTotalEntries;
          this.totalFinalFailedAmount = countData.finalPaymentFailedAmount;
          this.totalFinalFailedCount = countData.totalFailedEntriesCount;
          this.internalFailedAmount =
            countData.finalPaymentFailedAmount -
            countData.finalPaymentRbFailedAmount;
          this.internalFailedCount =
            countData.totalFailedEntriesCount -
            countData.finalPaymentRbFailedTotalEntries;
          this.rbretryButtonShow = countData.isRetryPossibleForRbFailure;
          this.rbretryButtonShowFinal =
            countData.isRetryPossibleForRbFinalPaymentFailure;
          this.advancePaidTotalAmount = countData.advancePaidTotalAmount;
          this.advanceReadyToPay = countData.advanceReadyToPay;
          this.advancePaidAmount = countData.advancePaidAmount;
          this.finalPayment = countData.finalPayment;
          this.readyToPay = countData.readyToPay;
          this.readyToPayAmount = countData.readyToPayAmount;
          this.finalReadyToPay = countData.finalReadyToPay;
          this.totalJob = countData.totalData;
          this.totalPages = Math.ceil(this.totalJob / 15);
        },
        (error) => {}
      );

      return v;
    },

    openTransporterUnassign(assignData) {
      this.confirmationDialog.removeTransporterAssignment = true;
      this.assignmentData = { ...assignData };
    },
    openDriverUnassign(assignData) {
      this.confirmationDialog.removeDriverAssignment = true;
      this.assignmentData = { ...assignData };
    },
    removeDriver() {
      this.confirmationDialog.removeDriverAssignment = false;
    },
    getDays(day) {
      let finalDate = moment(day);
      let dayToday = moment(new Date());
      let remainingDay = finalDate.diff(dayToday, "hours");
      return Math.round(remainingDay / 24);
    },

    remove() {
      this.confirmationDialog.removeTransporterAssignment = false;
    },
    removeTransporterAssignment(data) {
      if (
        data.assignmentStatus == 2 ||
        data.assignmentStatus == 5 ||
        data.assignmentStatus == 6
      ) {
        this.error = "Please unassign the driver first !";
        this.loading = false;
        return;
      }
      this.loading = true;
      let url = "/dmsAdmin/unassignTransporter";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        assignmentId: data.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.confirmationDialog.removeTransporterAssignment = false;
          this.componentKey = this.componentKey + 1;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },
    openRetryAllpopup(type) {
      if (type == "advance") {
        this.headingRetryPopup = "Confirmation";
        this.messageRetryPopup =
          " Are you sure you want to pay advance for all the real book failed  entries ?";
        this.dialogRetry = true;
      } else {
        this.headingRetryPopup = "Retry Posting Final Payment";
        this.messageRetryPopup =
          " Are you sure you want to pay  for all the failed  final payment real book entries ?";
        this.dialogRetry = true;
      }
    },
    retryAllAdvanceOrFinalPayment(type) {
      let url = "/dmsAdmin/advance/retry-rb-all-payment";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        action:
          type == "advance" ? "generateAdvanceReciept" : "finalPaymentToRB",
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialogRetry = false;

          this.x.message = response.data.data.message;
          (this.x.heading =
            type == "advance"
              ? "Advance Payment Update"
              : "Final Payment Update"),
            (this.x.success = true);
        },
        (error) => {
          this.dialogRetry = false;

          this.error = error.response.data.message;
        }
      );
    },
    removeDriverAssignment(data) {
      if (!data.driverData) {
        return;
      }
      let url = "/dmsAdmin/removeDriver";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const body = {
        driverId: data.driverData.driverId.toString(),
        assignmentId: data.assignmentId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.componentKey = this.componentKey + 1;
          this.confirmationDialog.removeDriverAssignment = false;
        },
        (error) => {
          this.error = this.$eventBus.parse(error);
          this.loading = false;
        }
      );
    },

    updatePaginationUrl() {
      let tempUrl = localStorage.getItem("paginationUrlAdvance");
      let x = tempUrl.split("&");
      let y = x[0].split("=");
      y[1] = this.workingCountry;
      x[0] = y.join("=");
      tempUrl = x.join("&");
      this.paginationURL = tempUrl;
    },

    page(event) {
      this.pages = event;
    },

    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    getTime(time) {
      return moment.unix(time).format("ll");
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getTime4(date) {
      return moment(date).format("ll LT");
    },

    filterByTab(value) {
      if (value == "paymentDetail") {
        this.$router.push(`/accounts`);
      } else if (value == "customerDetail") {
        this.$router.push(`/accounts/customerPayment`);
      } else {
        this.$router.push(`/accounts/advance`);
      }
    },

    searchData(dateRangeVal = null) {
      if (this.searchTypes.abbr) {
        this.loading = false;
        this.driverSearchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "driverId" },
          {
            status: "Driver Name",
            abbr: "driverName",
          },
          { status: "Truck Number", abbr: "truckNumber" },
          { status: "Tranporter ID", abbr: "transporterId" },
          {
            status: "Transporter Name",
            abbr: "Transporter Name",
          },
        ];

        this.driverSearchItems.forEach((k) => {
          if (k["abbr"] == this.searchTypes.abbr) {
            let elementPos = this.driverSearchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.driverSearchItems.splice(elementPos, 1);
          }
        });
      }
      if (this.driverSearchTypes.abbr) {
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "driverId" },
          {
            status: "Driver Name",
            abbr: "driverName",
          },
          { status: "Truck Number", abbr: "truckNumber" },
          { status: "Tranporter ID", abbr: "transporterId" },
          {
            status: "Transporter Name",
            abbr: "Transporter Name",
          },
        ];

        this.searchItems.forEach((k) => {
          if (k["abbr"] == this.driverSearchTypes.abbr) {
            let elementPos = this.searchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchItems.splice(elementPos, 1);
          }
        });
      }

      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      let url = [];
      url.push(`workingCountry=${this.workingCountry}`);

      url.push(`isSearch=true`);
      if (this.subJobType.value) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`jobType=${this.subJobType.value}`);
      }
      if (this.subTruckType) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`truckType=${this.subTruckType}`);
      }
      if (this.assignStatus) {
        //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`assignmentStatus=${this.assignStatus}`);
      }
      if (this.finalPaymentStatus && this.assignStatus == "Payment Pending") {
        url.push(`isAssignmentPaymentStatusFailed=${this.finalPaymentStatus}`);
      }

      if (dateRangeVal) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      } else if (this.startDate.length > 0) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      }

      if (this.advanceStatus) {
        url.push(`advanceStatus=${this.advanceStatus}`);
      }

      if (this.search.trim().length > 0 && this.searchTypes) {
        if (
          this.searchTypes.abbr == "jobId" ||
          this.searchTypes.abbr == "assignmentId" ||
          this.searchTypes.abbr == "driverId" ||
          this.searchTypes.abbr == "transporterId"
        ) {
          this.showCheckbox = true;
        }

        localStorage.setItem("searchAdvance", this.search);
        url.push(`searchType=${this.searchTypes.abbr}`);
        url.push(`searchText=${this.search}`);
      } else if (this.searchTypes) {
        if (!localStorage.getItem("searchAdvance")) {
        } else {
        }
        this.search = "";
      }

      if (this.driverSearch.trim().length > 0 && this.driverSearchTypes) {
        if (
          this.driverSearchTypes.abbr == "jobId" ||
          this.driverSearchTypes.abbr == "assignmentId" ||
          this.driverSearchTypes.abbr == "driverId" ||
          this.driverSearchTypes.abbr == "transporterId"
        ) {
          this.showCheckbox = true;
        }
        localStorage.setItem("searchAdvanceDriver", this.driverSearch);
        url.push(`searchTextTwo=${this.driverSearch}`);
        url.push(`searchTypeTwo=${this.driverSearchTypes.abbr}`);
      } else if (this.driverSearchTypes) {
        if (!localStorage.getItem("searchAssignDriver")) {
        } else {
        }
      }

      let finalUrl = "/dmsAdmin/accountAssignmentView?";
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";

      this.paginationURL = finalUrl;
      if (
        localStorage.getItem("searchAdvance") ||
        localStorage.getItem("searchAdvanceDriver") ||
        this.subJobType ||
        this.advanceStatus ||
        this.assignStatus ||
        this.subTruckType
      ) {
        this.getListCount();
      } else {
      }
    },
    reset() {
      (this.selectedids = []),
        (this.selectednames = []),
        (this.assignStatus = "");
      (this.generateInvoiceCount = 0), (this.advanceStatus = "");
      this.showCheckbox = false;
      this.subJobType = "";
      this.assignStatus = "";
      this.finalPaymentStatus = "";
      this.dateRange2 = null;
      this.startDate = "";
      this.endDate = "";
      this.subTruckType = "";
      this.driverSearchTypes = "";
      this.driverSearch = "";
      this.searchTypes = "";
      this.search = "";
      this.advanceRequested = 0;
      this.advanceRequestedAmount = 0;
      this.advanceReadyToPayAmount = 0;
      this.advancePaidTotalAmount = 0;
      this.advanceReadyToPay = 0;
      this.advancePaidAmount = 0;
      this.finalPayment = 0;
      this.readyToPay = 0;
      this.readyToPayAmount = 0;
      this.failedAdvanceAmount = 0;
      this.failedAdvanceAmountCount = 0;
      this.finalReadyToPay = 0;
      this.totalPages = 0;
      this.totalJob = 0;
      this.totalPendingFinalPayAmount = 0;
      this.totalPendingFinalPayCount = 0;
      this.finalPaymentRbFailedAmount = 0;
      this.finalPaymentRbFailedCount = 0;
      // localStorage.removeItem("paginationUrlAdvance");
      // localStorage.removeItem("searchAdvance");
      // localStorage.removeItem("searchAdvanceDriver");

      let fullPath = this.$router.currentRoute.fullPath;

      this.driverSearchItems = [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        { status: "Driver ID", abbr: "driverId" },
        {
          status: "Driver Name",
          abbr: "driverName",
        },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Tranporter ID", abbr: "transporterId" },
        {
          status: "Transporter Name",
          abbr: "Transporter Name",
        },
      ];
      this.searchItems = [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        { status: "Driver ID", abbr: "driverId" },
        {
          status: "Driver Name",
          abbr: "driverName",
        },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Tranporter ID", abbr: "transporterId" },
        {
          status: "Transporter Name",
          abbr: "Transporter Name",
        },
      ];
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.xerror = "";
        }, 3000);
        return;
      }
      this.paginationURL = `/dmsAdmin/accountAssignmentView?workingCountry=${this.workingCountry}&isSearch=true`;
      this.getListCount();
      return;
    },
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    closeMessage() {
      this.x.success = false;
      this.refresh();
    },
    updateQueryStringParameter(key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = this.paginationURL.indexOf("?") !== -1 ? "&" : "?";
      if (this.paginationURL.match(re)) {
        this.paginationURL = this.paginationURL.replace(
          re,
          "$1" + key + "=" + value + "$2"
        );
      } else {
        this.paginationURL = this.paginationURL + separator + key + "=" + value;
      }
    },
  },
};
</script>

<style scss>
.pd-t-l {
  padding-left: 5px;
  padding-top: 7px;
}
div#ej2-datetimepicker_0_popup {
  left: 905.2px !important;
}
#accountDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#accountDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 100px !important;
  width: 100%;
  text-align: right;
}
#accountDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#accountDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.font_Bold {
  font-size: 13px;
  font-weight: 600;
  margin-top: 2px;
  font-family: "Open Sans bold", sans-serif;
}
.reset__btn {
  position: absolute;
  right: 0;
  top: -4px !important;
}
.total.avatar[data-v-6c1d3ab2] {
  min-width: 253px !important;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
.account-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid darkorange;
  padding: 2px 8px;
  border-radius: 5px;
  margin-top: 6px;
}
.searchAccounts .v-label.v-label--is-disabled.theme--light {
  left: 10px !important;
}

.searchAccounts .theme--light.v-label {
  left: 10px !important;
}

.assign-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid darkorange;
  padding: 2px 8px;
  border-radius: 5px;
}

.searchAccounts input {
  padding-left: 10px !important;
}

div#ej2-datetimepicker_0_popup {
  left: 827.2px !important;
}
/* .date_9 input#ej2-datetimepicker_0 {
  font-size: 16px;
} */
</style>

<style scoped>
.divider-style {
  margin-bottom: 24px;
  margin-top: 15px;
  background: lightgrey;
}

.scrollbar {
  width: 5px;
}
.tooltip-op-comment {
  max-width: 30%;
  opacity: 1 !important;
}
.advance-btn-color {
  background-color: #e3471b !important;
  text-transform: capitalize;
  width: 22%;
  border-radius: 5px;
}

.div ::-webkit-scrollbar {
  width: 1 px;
}
.muted {
  font-size: 13px;
}
.layout {
  overflow: auto;
}
.tra-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: white;
  background-color: orange;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 6px;
}
.orange-text {
  color: orange;
}

.download-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: blue;
  border-radius: 5px;
  border: 2px solid blue;
  padding: 4px 5px;
  margin-right: 2px;
}
.spMargin {
  margin-top: -21px;
}
.red-text {
  color: red;
  animation: animate 1.5s linear infinite;
}
.red-text2 {
  color: red;
}
.account-disabled-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 2px 8px;
  border-radius: 5px;
}
@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}
</style>
