<template>
  <div class="log">
    <button
      type="button"
      color="orange"
      outline
      class="pay-ass-btn green-button"
      v-if="
        priceData.assignmentStatus != 4 &&
        priceData.assignmentStatus != 7 &&
        priceData.isPurchaseInvoiceGenerated &&
        priceData.isReadyForFinalPayment &&
        PendingAmount > 0
      "
      @click.stop="dialog = false"
      @click="check()"
    >
      {{
        priceData.ownerType == "Transporter" ? "Pay Transporter" : "Pay Driver"
      }}
    </button>
    <button
      type="button"
      color="orange"
      outline
      class="pay-ass-btn green-button"
      v-else-if="
        priceData.assignmentStatus != 4 &&
        priceData.assignmentStatus != 7 &&
        !priceData.isPurchaseInvoiceGenerated &&
        priceData.customerCurrency === 'PKR' &&
        priceData.isReadyForFinalPayment &&
        PendingAmount > 0 &&
        priceData.totalReadyToPayAmount == 0
      "
      @click.stop="dialog = false"
      @click="check()"
    >
      {{
        priceData.ownerType == "Transporter" ? "Pay Transporter" : "Pay Driver"
      }}
    </button>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
      class="layout__spacing"
    >
      <v-form
        ref="form"
        onsubmit="return false;"
        class="layout__spacing"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline">
              {{
                priceData.ownerType == "Transporter"
                  ? "Final Payment To Transporter"
                  : "Final Payment To Driver"
              }}(Assignment ID {{ priceData.assignmentId }})</span
            >
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer style="max-height: 550px !important">
            <v-card-text>
              <v-layout
                class="pb-3"
                v-if="!priceData.ownerType == 'Transporter'"
              >
                <v-flex xs12 md12>
                  <div class="sub-heading muted">
                    Driver’s Payment credit period :
                    <span style="color: black" class="heading"
                      >{{ driverCreditDuration ? driverCreditDuration : "" }}
                      {{ driverCreditDuration ? "days" : "N.A" }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3">
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Total Driver Price :
                    <span style="color: black" class="heading"
                      >{{
                        priceData.paymentAmount
                          ? priceData.paymentAmount +
                            priceData.totalAdditionalAmount
                          : 0
                      }}
                      {{ priceData.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Driver Price :
                    <span style="color: black" class="heading"
                      >{{ priceData.paymentAmount }}
                      {{ priceData.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Additional Charges :
                    <span style="color: black" class="heading break-word">
                      {{ priceData.totalAdditionalAmount || "0" }}
                      {{ priceData.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3">
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Advance Paid :
                    <span style="color: black" class="heading"
                      >{{ priceData.totalAdvancePaidAmount || "0" }}
                      {{ priceData.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Amount Paid Till Date:
                    <span style="color: black" class="heading"
                      >{{
                        priceData.amountPaidToDriver +
                          priceData.totalAdvancePaidAmount || "0"
                      }}
                      {{ priceData.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Pending Amount :
                    <span style="color: black" class="heading"
                      >{{ PendingAmount.toFixed(2) }}
                      {{ priceData.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs10>
                  <v-tabs
                    style="margin-bottom: 20px"
                    v-model="tabStatus"
                    fixed-tabs
                    background-color="darkorange"
                    dark
                    class="noMarginLeft main__tabs"
                  >
                    <v-tab
                      href="#Driver"
                      v-if="!priceData.ownerType == 'Transporter'"
                    >
                      Driver Payment Details</v-tab
                    >
                    <v-tab href="#Debtor"> Payment Details </v-tab>
                  </v-tabs>
                </v-flex>

                <v-flex
                  xs5
                  class="pr-1 pt-2 pb-3 pt-100"
                  v-if="document.length < 10"
                >
                  <div class="noBorder upload-btn-wrapper">
                    <button v-if="document.length < 10" class="btn">
                      <v-icon small color="orange">upload</v-icon> Upload Proof
                      Of Payment
                    </button>
                    <input
                      ref="fileUpload"
                      id="fileUpload"
                      type="file"
                      multiple
                      accept=".pdf, .jpeg, .jpg, .png"
                      @change="uploadDocument"
                    />
                  </div>
                </v-flex>
              </v-layout>

              <v-layout v-if="tabStatus === 'Driver'">
                <v-flex xs12 md12>
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="paymentMode"
                    row
                    :mandatory="true"
                    readonly
                    :rules="[rules.required]"
                  >
                    <div>
                      <div class>
                        <v-layout>
                          <v-radio
                            label="Cash"
                            value="Cash"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>

                          <v-radio
                            label="Bank Transfer"
                            value="Bank Transfer"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>
                        </v-layout>
                      </div>
                    </div>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout v-if="tabStatus === 'Driver'">
                <v-flex md5 class="payadvance">
                  <v-flex md12 pb-3>
                    <v-text-field
                      v-model="accountHolderName"
                      label="Account Holder Name"
                      placeholder="Enter account holder name"
                      class="pt-0 currencyTitle"
                      :value="accountHolderName"
                      readonly
                    ></v-text-field>
                  </v-flex>

                  <v-layout>
                    <v-flex md12 class="payadvance">
                      <v-text-field
                        v-model="ifscCode"
                        label="IBAN Number"
                        placeholder="Enter IBAN number"
                        readonly
                        class="pt-0 currencyTitle"
                        maxlength="25"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex md6 pl-5 class="payadvance">
                  <v-layout>
                    <v-flex md12 pb-3 class="payadvance">
                      <v-text-field
                        v-model="accountNumber"
                        label="Account Number"
                        readonly
                        placeholder=" Enter Account Number"
                        class="pt-0 currencyTitle"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-layout>
                      <v-flex md12 class="payadvance">
                        <v-text-field
                          v-model="swiftNumber"
                          label="Swift Number"
                          class="pt-0 currencyTitle"
                          readonly
                          placeholder="Enter Swift Number"
                          :value="swiftNumber"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex xs12 md12>
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="debtorPaymentMode"
                    row
                    :mandatory="true"
                    :rules="[rules.required]"
                    @change="getBankName()"
                  >
                    <div>
                      <div class>
                        <v-layout>
                          <v-radio
                            label="Cash"
                            value="Cash"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>

                          <v-radio
                            label="Bank Transfer"
                            value="Bank Transfer"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>
                        </v-layout>
                      </div>
                    </div>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex xs5 sm5 v-if="debtorPaymentMode === 'Cash'">
                  <v-autocomplete
                    v-if="
                      priceData.customerCurrency === 'AED' ||
                      priceData.customerCurrency === 'SAR'
                    "
                    label="Select Cash"
                    placeholder="Select Cash"
                    :items="banks"
                    class="pt-0 currencyTitle"
                    v-model="bankName"
                    :disabled="
                      !this.$props.invoiceCity || !this.$props.invoiceCompany
                    "
                    item-text="text"
                    item-value="value"
                  />

                  <v-text-field
                    v-if="priceData.customerCurrency == 'PKR'"
                    v-model="bankName"
                    label="Select Cash"
                    class="pt-0 currencyTitle"
                    placeholder="Select Cash"
                    :value="bankName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 sm5 v-else>
                  <v-autocomplete
                    v-if="
                      priceData.customerCurrency === 'AED' ||
                      priceData.customerCurrency === 'SAR'
                    "
                    label="Choose Bank Detail"
                    placeholder="Choose Bank Detail"
                    :items="banks"
                    class="pt-0 currencyTitle"
                    v-model="bankName"
                    :disabled="
                      !this.$props.invoiceCity || !this.$props.invoiceCompany
                    "
                    item-text="text"
                    :rules="[rules.debtorBank]"
                    item-value="value"
                  />

                  <v-text-field
                    v-if="priceData.customerCurrency == 'PKR'"
                    v-model="bankName"
                    label="Bank Name"
                    class="pt-0 currencyTitle"
                    placeholder="Bank Name"
                    :value="bankName"
                    :rules="[rules.noWhiteSpace, rules.debtorBankName]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 pl-5>
                  <div class="heading">
                    <v-text-field
                      style="padding-top: 2px"
                      label="Amount To be Paid"
                      placeholder="Amount To be Paid"
                      :suffix="priceData.customerCurrency"
                      v-model="driverAmount"
                      class="pt-0 currencyTitle"
                      :rules="[rules.amountToBePaid]"
                    />
                  </div>
                </v-flex>
              </v-layout>
              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex xs5 pt-3>
                  <span class="muted"></span>
                  <v-menu
                    ref="FilterDateBool"
                    lazy
                    v-model="FilterDateBool"
                    class="date__icon"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    full-width
                    :nudge-right="40"
                    min-width="290px"
                    :return-value.sync="startDate"
                  >
                    <v-text-field
                      slot="activator"
                      :label="'Select Actual Date '"
                      placeholder="Select Actual Date"
                      v-model="startDate"
                      prepend-icon="event"
                      readonly
                      class="pt-0 currencyTitle"
                      :rules="[rules.date]"
                    ></v-text-field>
                    <v-date-picker
                      v-model="startDate"
                      :max="dateToday"
                      class="minHeight"
                      @change="$refs.FilterDateBool.save(startDate)"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs5 pl-5 style="position: relative">
                  <label
                    :class="
                      actualModeError
                        ? 'light_red label__position__acc muted'
                        : 'label__position__acc muted'
                    "
                    style="font-size: 10px !important"
                    >Select Actual Time</label
                  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    :class="
                      actualModeError
                        ? 'light_red_fill clock_account_pay'
                        : 'clock_account_pay'
                    "
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                  </svg>
                  <vue-timepicker
                    class="fonttime"
                    :minute-interval="30"
                    v-model="pickupTime"
                    input-width="28em"
                    :label="'Select Actual Time'"
                    placeholder="Select Actual Time"
                    close-on-complete
                    :rules="[rules.required]"
                  ></vue-timepicker>

                  <span
                    v-if="actualModeError"
                    style="
                      color: #ff5252 !important;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                      font-size: 12px;
                    "
                    >{{ errorMessage }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex xs12 md12 class="pr-4" pt-3>
                  <v-text-field
                    :rules="[rules.comment]"
                    class="pt-0 currencyTitle"
                    label="Comments"
                    placeholder="Comments"
                    v-model="Comments"
                  />
                </v-flex>
              </v-layout>

              <v-layout>
                <v-card-text class="nospace">
                  <v-container class="p-16">
                    <v-layout pl-2> </v-layout>
                    <v-flex xs12 pt-2>
                      <ul style="padding: 0px !important">
                        <li
                          style="position: relative"
                          :key="i"
                          v-for="(doc, i) in document"
                          class="list"
                          ref="documentList"
                        >
                          <button
                            type="button"
                            v-show="document"
                            @click="removeImage(i)"
                          >
                            <span class="close imageClose">&times;</span>
                          </button>
                          <a
                            :href="doc"
                            class="document-link"
                            v-if="
                              doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'
                            "
                            target="_blank"
                          >
                            <img src="../../../assets/pfd.png" />
                          </a>
                          <a
                            :href="doc"
                            class="document-link"
                            target="_blank"
                            v-else
                          >
                            <img
                              src="../../../assets/docimg.webp"
                              height="72"
                              width="75"
                            />
                          </a>
                        </li>
                      </ul>
                    </v-flex>
                  </v-container>
                </v-card-text>
              </v-layout>
            </v-card-text>
          </v-spacer>
          <v-card-actions>
            <!-- <v-container>
                    
                </v-container> -->
            <v-layout>
              <v-spacer />
              <v-btn flat @click.native="close()">Close</v-btn>
              <v-btn
                :loading="processing"
                color="orange darken-2"
                class="white--text"
                @click="submit()"
                >Pay</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-dialog>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :heading="x.heading"
      :onclose="closeMessage"
    />

    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="gpsError"
      class="white--text"
      v-if="gpsError"
      >{{ gpsError }}</v-snackbar
    >
  </div>
</template>

<script>
import { StorageKeys } from "../../../constants/constants";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import SuccessDialog from "@/components/Common/SuccessDialog";

import moment from "moment";
import {
  addProofOfDelivery,
  uploadImage,
} from "../../../constants/api-urls.js";
export default {
  data() {
    return {
      paymentMode: "",
      actualModeError: false,
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      paymentType: null,
      accountHolderName: "",
      tabStatus: "Driver",
      swiftNumber: "",
      startDate: "",
      FilterDateBool: false,

      pickupTime: {
        HH: "",
        mm: "",
      },
      draftNo: "",
      accountNumber: "",
      ifscCode: "",
      advanceAmount: "",
      debtorPaymentMode: "Cash",
      Comments: "",
      banks: [],
      cheque: "",
      draftNo: "",
      operationName: "",
      processing: false,
      advanceAmount: "",
      dialog: false,
      errorMessage: "Select Actual Time is required",
      voucher: "",
      document: [],
      valid: true,
      lazy: false,
      documents: [],
      error: null,
      gpsError: null,
      driverAmount: "",
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,
        heading: "Final Payment Update",
        error: null,
        missingField: null,
      },

      banks: [],
      bankName: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        debtorBank: (value) => !!value || " Bank is required.",
        selectCash: (value) => !!value || "Select Cash Field is required.",
        debtorBankName: (value) => !!value || " Bank Name is required.",
        amountToBePaid: (value) => !!value || "Amount To be Paid is required.",
        date: (value) => !!value || " Select Actual Date is required.",
        comment: (value) => value != "" || " Comment  is required.",

        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
    };
  },
  created() {
    //this.getBankName();
  },
  props: {
    priceData: Object,
    invoiceCity: String,
    invoiceCompany: String,
    driverCreditDuration: Number,
    PendingAmount: Number,
  },
  components: {
    VueTimepicker,
    SuccessDialog,
  },
  watch: {
    pickupTime(val) {
      //  this.actualModeError = false;
      if (val.HH || val.mm) {
        this.checkTime();
        this.actualModeError = false;
      }
    },
  },
  methods: {
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;

      var time = this.pickupTime.HH + ":" + this.pickupTime.mm;

      if (time != "" && !time.match(re)) {
        this.actualModeError = true;

        this.processing = false;
        this.errorMessage = "Please select the valid";

        return false;
      }
    },
    async check() {
      // console.log(this.priceData);
      this.getBankName();
      this.processing = true;
      this.operationName = "pending-amount-for-driver-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.open();
      } else {
        this.processing = false;
        return;
      }
    },
    closeMessage() {
      this.x.success = false;
    },
    open() {
      // console.log(this.priceData);
      if (
        this.priceData.assetId &&
        this.priceData.imeiInstallStatus &&
        this.priceData.imeiInstallStatus != "collected"
      ) {
        this.dialog = false;
        this.gpsError = "Kindly collect the gps device";
      } else if (this.priceData.assetId && !this.priceData.imeiInstallStatus) {
        this.dialog = false;
        this.gpsError = "Kindly collect the gps device";
      } else {
        // this.paymentMode = "Cash";
        // console.log(this.priceData.creditorIBANNumber);
        this.driverAmount = Number(this.PendingAmount);

        this.tabStatus = "Debtor";
        this.paymentMode = this.priceData.creditorPaymentMode;
        this.ifscCode = this.priceData.creditorIBANNumber;

        this.swiftNumber = this.priceData.creditorSwiftNo;

        this.accountHolderName = this.priceData.creditorAccountHolderName;

        this.accountNumber = this.priceData.creditorAccountNumber;

        this.voucher = this.priceData.creditorVoucherNo;

        this.draftNo = this.priceData.creditorDraftNo;

        this.cheque = this.priceData.creditorChequeNo;

        //  this.getAdditionalCharges();
        this.dialog = true;
      }
    },
    getBankName() {
      if (
        (this.$props.invoiceCompany == "2" &&
          this.debtorPaymentMode == "Cash") ||
        (this.$props.invoiceCompany == "4" && this.debtorPaymentMode == "Cash")
      ) {
        // this.bankName = "Cash";
        this.banks = [];
        this.banks = ["Cash"];
      } else {
        // if (this.$props.invoiceCompany == "1") {
        this.banks = [];
        let obj = {
          Cash: "cash",
          "Bank Transfer": "bankTransfer",
        };
        console.log(obj[this.debtorPaymentMode]);

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          country: this.$props.invoiceCompany,
          modeOfPayment:
            this.$props.invoiceCompany == "2"
              ? "bankTransfer"
              : obj[this.debtorPaymentMode],
        };
        let { apiUrl } = this.constants;
        this.axios
          .post(`${apiUrl}/dmsAdmin/get-ledger-details`, body, config)
          .then(
            (response) => {
              const { data = null } = response.data;
              console.log(response.data);
              data.forEach((element) => {
                console.log(element);
                this.banks.push({
                  text: element.ledgerName,
                  value: element.ledgerName,
                });
              });

              // this.bankName = response.data.data[2].bankName;
              // this.banks = response.data.data;
            },
            (error) => {
              this.error = "Something went wrong. Please try again later!";
              this.loading = false;
            }
          );
      }
      // } else if (
      //   this.$props.invoiceCompany == "2" &&
      //   (this.$props.invoiceCity == "1" || this.$props.invoiceCity == "2")
      // ) {
      //   this.banks = ["Mashreq Bank PSJC", "EMIRATES NBD Bank", "ADCB -  0001"];
      // } else {
      //   return;
      // }
    },

    removeImage(key) {
      this.document.splice(key, 1);
    },
    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.error = "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 10) {
              this.document.push(response.data.data.original);
            } else {
              this.error = "Maximum 10 proofs can be uploaded!";
              return false;
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.processing = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },

    close() {
      //this.onClose();
      this.dialog = false;
      this.paymentMode = "";
      this.actualModeError = false;

      (this.pickupTime = {
        HH: "",
        mm: "",
      }),
        (this.bankName = "");
      this.draftNo = "";
      this.swiftNumber = "";
      this.cheque = "";
      this.voucher = "";
      this.ifscCode = "";
      this.accountHolderName = "";
      this.accountNumber = "";
      this.startDate = "";
      this.driverAmount = this.priceData.totalAdvancePaidAmount
        ? this.priceData.paymentAmount - this.priceData.totalAdvancePaidAmount
        : this.priceData.paymentAmount;
      this.error = null;
      this.document = [];
      this.Comments = "";
      this.$refs.form.resetValidation();

      this.$eventBus.$emit("close-confirmation-dialog");
    },

    settleFNF() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const url = `${this.constants.apiUrl}/dmsAdmin/settleFnfRequest`;
      this.axios
        .post(
          url,
          {
            assignmentId: this.assignmentId.toString(),
          },
          config
        )
        .then((response) => {
          this.processing = false;
          return true;
        })
        .catch((e) => {
          this.error = "Payment failed. Try again later";
          return false;
        });
    },
    async submit() {
      this.processing = true;
      this.operationName = "pending-amount-for-driver-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        this.error = null;

        this.processing = true;

        if (this.$refs.form.validate()) {
          if (
            this.pickupTime.HH == "" &&
            this.pickupTime.mm == "" &&
            this.tabStatus === "Debtor"
          ) {
            this.actualModeError = true;
            (this.errorMessage = "Select Actual Time is required"),
              (this.processing = false);
            return false;
          }
          if (
            !this.debtorPaymentMode ||
            !this.driverAmount ||
            !this.startDate ||
            !this.Comments ||
            this.pickupTime === { HH: "", mm: "" } ||
            !this.pickupTime ||
            !this.driverAmount
          ) {
            this.processing = false;
            this.error = "Please fill debtor details!";

            return false;
          }

          if (this.debtorPaymentMode === "Bank Transfer" && !this.bankName) {
            this.processing = false;

            this.error = "Please fill debtor details!";
            return false;
          }

          if (this.driverAmount > this.PendingAmount) {
            let greaterAmount = this.PendingAmount;

            this.processing = false;

            this.error =
              "You can't pay driver greater than " +
              greaterAmount +
              " " +
              this.priceData.customerCurrency;
            return false;
          }

          if (this.pickupTime === { HH: "", mm: "" }) {
            (this.errorMessage = "Select Actual Time is required"),
              (this.actualModeError = true);
            return false;
          }
          if (this.pickupTime.HH != " " && this.pickupTime.mm != "") {
            this.actualModeError = false;
            this.checkTime();

            let currentDate = moment(new Date()).format("YYYY-MM-DD, HH:mm");
            let selectedDateTime =
              this.startDate +
              "," +
              this.pickupTime.HH +
              ":" +
              this.pickupTime.mm;

            if (
              new Date(currentDate).getTime() <
              new Date(selectedDateTime).getTime()
            ) {
              this.processing = false;
              this.error =
                "Please select the past time and date before moving ahead!";
              return false;
              t;
            }
          }
          if (!this.document.length) {
            this.error = "Please upload proof ";
            this.processing = false;

            return false;
          }

          let url = "/dmsAdmin/assignmentPayment";

          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          const body = {
            assignmentId: this.priceData._id,
            paymentMode: this.debtorPaymentMode.toString(),
            paidDate: this.startDate,
            paidTime: this.pickupTime.HH + ":" + this.pickupTime.mm,
            paymentAmount: this.priceData.paymentAmount.toString(),
            amount: this.driverAmount.toString(),
            comment: this.Comments.trim(),
            proof: this.document,
          };
          if (this.bankName) {
            if (
              (this.$props.invoiceCompany == "2" &&
                this.debtorPaymentMode == "Cash") ||
              (this.$props.invoiceCompany == "4" &&
                this.debtorPaymentMode == "Cash")
            ) {
            } else {
              body.bankName = this.bankName;
            }
          }
          if (
            (this.bankName && this.$props.invoiceCompany == "1") ||
            (this.bankName && this.$props.invoiceCompany == "2") ||
            (this.bankName && this.$props.invoiceCompany == "4")
          ) {
            if (
              (this.$props.invoiceCompany == "2" &&
                this.debtorPaymentMode == "Cash") ||
              (this.$props.invoiceCompany == "4" &&
                this.debtorPaymentMode == "Cash")
            ) {
            } else {
              body.ledgerName = this.bankName;
            }
          }

          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              // this.x.success = true;

              this.dialog = false;
              this.processing = false;

              this.close();
              this.paymentMode = "";
              this.$emit("payment-list-refresh");
              this.x.message = response.data.data.message;
              this.x.heading = "Final Payment Update";
              // this.x.success = true;
              this.error = "";
            },
            (error) => {
              console.log(error);
              console.log(error.response.data.message);
              this.processing = false;
              this.error = error.response.data.message;
            }
          );
        } else {
          this.processing = false;
          if (
            this.pickupTime.HH == "" &&
            this.pickupTime.mm == "" &&
            this.tabStatus === "Debtor"
          ) {
            this.actualModeError = true;
            (this.errorMessage = "Select Actual Time is required"),
              (this.processing = false);
            return false;
          }
        }
      } else {
        this.processing = false;
        //this.error = "All fields are mandatory";

        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.break-word {
  word-break: break-all;
}
.pay-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid orange;
  padding: 2px 12px;
  border-radius: 5px;
}
.pay-not-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 4px 12px;
  border-radius: 5px;
}
.custom-file-upload {
  border: none;
  display: inline-block;
  background: orange;
  font-size: 16px;
  color: #fff;
  padding: 10px 12px;
  cursor: pointer;
}
.document-link {
  text-decoration: none;
}
.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
.fonttime {
  font-size: 12px !important;
}
.list {
  display: inline-block;
  position: relative;
}
.fonttime .dropdown.drop-down {
  top: -14%;
}
</style>
