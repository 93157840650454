<template>
  <v-card class="nospace">
    <loading :active.sync="loading" />
    <v-card-text class="nospace">
      <v-data-table
        id="dataTable"
        hide-actions
        item-key="jobId"
        :headers="headers"
        :items="items"
        hide-default-footer
      >
        <template slot="items" slot-scope="props">
          <td v-if="props.item.assignmentType == 'Value Added Service'">
            <span style="color: blue"> {{ props.item.assignmentId }}</span>
          </td>
          <td v-else>
            {{ props.item.assignmentId }}
          </td>
          <td>{{ assignmentStatus(props.item.assignmentStatus) }}</td>
          <td v-if="props.item.driverData">
            <v-flex
              v-if="!props.item.transporterData && props.item.driverData.name"
            >
              <v-icon
                color="red"
                class="white--text"
                small
                @click="check(props.item.driverData.driverId)"
                title="Driver Details"
                >visibility</v-icon
              ></v-flex
            >
            <v-flex v-if="props.item.transporterData">
              <v-icon
                color="red"
                class="white--text"
                v-permissions="'view-profile-driver'"
                small
                @click.stop="dialog = false"
                @click="
                  checkTransporterDetail(
                    props.item.transporterData.transporterId,
                    props.item.transporterData._id,
                    props.item.driverData.driverId,
                    props.item.VendorWorkingCountry
                  )
                "
                title="Transporter Details"
                >visibility</v-icon
              ></v-flex
            >

            <v-flex v-if="props.item.driverData.name">
              {{ props.item.driverData.name }}<br />{{
                props.item.driverData.countryCode
              }}-{{ props.item.driverData.phoneNo }} |

              <div v-if="props.item.truckPrefix">
                {{ props.item.truckPrefix }}-{{ props.item.truckNumber }}
              </div>
              <div v-else>
                {{ props.item.driverData.truckPrefix }}-{{
                  props.item.driverData.truckNumber
                }}
              </div>
            </v-flex>
            <v-flex v-else>
              <div class="pr-2">N.A.</div>
            </v-flex>
          </td>
          <td v-else>-</td>
          <td>
            {{ props.item.perAssignmentPrice }}
            {{ props.item.customerCurrency }}
          </td>
          <td v-if="props.item.paymentAmount || props.item.paymentAmount == 0">
            <v-layout class="justfy_c">
              <span style="padding-top: 12px">
                {{ props.item.paymentAmount }}
                {{ props.item.paymentCurrency }}
              </span>
              <div v-if="!jobData.isJobActivatedByScript">
                <driverPrice
                  v-permissions="'edit-driver-price-in-accounts'"
                  :driverAmount="props.item.paymentAmount"
                  :perAssignmentPrice="props.item.perAssignmentPrice"
                  :assignmentStatus="props.item.assignmentStatus"
                  :currency="props.item.paymentCurrency"
                  :isCustomerReceiptInitiate="
                    props.item.isCustomerReceiptInitiated
                  "
                  :advanceRequestedAmount="props.item.advanceAmount"
                  :assignId="props.item.assignmentId"
                  @driver-price-list-refresh="refresh()"
                /></div
            ></v-layout>
            <v-layout>
              <confirmationPopup
                v-if="props.item.assignmentStatus == 8"
                v-permissions="'approve-reject-driver-price'"
                :message="`Are you sure you want to approve higher driver price( ${props.item.paymentAmount} ${props.item.paymentCurrency}) than the customer price(${props.item.perAssignmentPrice} ${props.item.paymentCurrency}) for assignment id ${props.item.assignmentId}?`"
                :confirmationTitle="`Approve Driver Price For Assignment Id ${props.item.assignmentId}`"
                :cancelBtn="'Cancel'"
                :confirmBtn="'Approve'"
                :customerData="props.item"
                :type="'driverApprove'"
                :assignmentId="props.item.assignmentId"
                @refresh-list="refresh()" />
              <CancellationPopup
                v-if="props.item.assignmentStatus == 8"
                v-permissions="'approve-reject-driver-price'"
                :message="`Are you sure you want to Reject higher driver price for this assignment ?`"
                :confirmationTitle="`Reject Driver Price For Assignment Id ${props.item.assignmentId}`"
                :cancelBtn="'Cancel'"
                :type="'driverApprove'"
                :assignmentId="props.item.assignmentId"
                :confirmBtn="'Reject'"
                :customerData="props.item"
                @refresh-list="refresh()"
            /></v-layout>
          </td>
          <td v-else>N.A.</td>
          <td>
            <span style="padding-top: 6px">
              Misc:&nbsp;
              {{ props.item.totalMiscellaneousCharge }}
              {{ props.item.paymentCurrency }}
            </span>
            <br />
            <span style="padding-top: 6px">
              Driver:&nbsp;
              {{ props.item.totalAdditionalAmount }}
              {{ props.item.paymentCurrency }}
            </span>
            <br />
            <span style="padding-top: 6px">
              Customer:&nbsp;
              {{ props.item.totalCostMargin }}
              {{ props.item.paymentCurrency }}
            </span>
            <br />
          </td>
          <td>
            <v-layout class="justfy_c">
              <span style="padding-top: 12px">
                {{
                  props.item.advanceAmount ? props.item.advanceAmount : "N.A."
                }}
                {{
                  props.item.advanceAmount ? props.item.paymentCurrency : ""
                }}</span
              >
              <!-- <generateAdvancePopup
                v-if="props.item.paymentAmount"
                :advancePaid="props.item.totalAdvancePaidAmount"
                v-permissions="'add-advance-for-accounts'"
                :assignId="props.item._id"
                @account-list-refresh="refresh()"
            /> -->
            </v-layout>
          </td>

          <td>
            <v-layout class="justfy_c"
              ><span class="pd_5"
                >{{
                  props.item.totalAdvancePaidAmount
                    ? props.item.totalAdvancePaidAmount
                    : "N.A."
                }}
                {{
                  props.item.totalAdvancePaidAmount
                    ? props.item.paymentCurrency
                    : ""
                }}</span
              >
              <activityLogs
                :permissions="'activity-logs-for-accounts'"
                class="pd_5"
                :type="'jobs'"
                :section="'advance'"
                :subType="5"
                :assignmentId="props.item._id"
                :_id="jobId"
                :id="props.item.jobId.toString()"
            /></v-layout>
          </td>

          <td>
            <v-flex v-if="props.item.VendorWorkingCountry == 'PAK'">-</v-flex>
            <v-flex
              v-else-if="
                props.item.VendorWorkingCountry != 'PAK' &&
                props.item.isPurchaseOrderInQueue
              "
            >
              Background process is ongoing
            </v-flex>
            <v-flex
              v-else-if="
                props.item.assignmentStatus == -1 &&
                props.item.VendorWorkingCountry != 'PAK'
              "
            >
              <div>Assignment is cancelled</div>
            </v-flex>
            <v-flex
              v-else-if="
                props.item.assignmentStatus == 8 &&
                props.item.VendorWorkingCountry != 'PAK'
              "
            >
              <div>
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      class="assign--disable-ass-btn"
                      flat
                      prepend-icon
                      type="button"
                      v-permissions="'generate-downaload-po-pi-for-accounts'"
                      color="grey"
                      disabled
                    >
                      <v-icon style="color: lightgray; !important" small
                        >settings</v-icon
                      >PO
                    </button></template
                  >
                  <span class="tooltip">Driver is Partially assigned .</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex
              v-else-if="
                (props.item.assignmentStatus == 1 ||
                  props.item.assignmentStatus != -1) &&
                !jobData.workOrderCompleted &&
                props.item.VendorWorkingCountry != 'PAK'
              "
            >
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="assign--disable-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    <v-icon style="color: lightgray; !important" small
                      >settings</v-icon
                    >PO
                  </button></template
                >
                <span class="tooltip"
                  >Complete shipment to create purchase order.</span
                >
              </v-tooltip>
            </v-flex>
            <v-flex
              v-else-if="
                !props.item.purchaseOrderCreated &&
                props.item.errorWhileRealbooks &&
                props.item.driverId &&
                props.item.VendorWorkingCountry != 'PAK' &&
                props.item.assignmentStatus != -1
              "
            >
              <div v-if="props.item.isPurchaseOrderInQueue">
                Background process is ongoing
              </div>
              <div v-else-if="!props.item.isPurchaseOrderInQueue">
                <span
                  v-if="
                    props.item.isPurchaseOrderBackgroundProcess &&
                    props.item.errorWhileRealbooks
                  "
                  >Data inappropriate, kindly update data</span
                >
                <button
                  class="assign-ass-btn"
                  type="button"
                  v-permissions="'generate-downaload-po-pi-for-accounts'"
                  flat
                  v-if="
                    !props.item.isPurchaseOrderBackgroundProcess &&
                    props.item.errorWhileRealbooks
                  "
                  prepend-icon
                  color="orange"
                  :disabled="loading"
                  @click="retryPO(props.item.assignmentId)"
                  :_id="props.item.assignmentId"
                >
                  <v-icon color="orange" small>settings</v-icon>Generate
                </button>
              </div>
            </v-flex>

            <v-flex
              v-else-if="
                !props.item.purchaseOrderCreated &&
                !props.item.errorWhileRealbooks &&
                props.item.driverId &&
                props.item.VendorWorkingCountry != 'PAK' &&
                (props.item.assignmentStatus != 1 ||
                  props.item.assignmentStatus != -1)
              "
            >
              <div v-if="props.item.isPurchaseOrderInQueue">
                Background process is ongoing
              </div>
              <div v-else-if="!props.item.isPurchaseOrderInQueue">
                <button
                  class="assign-ass-btn"
                  flat
                  prepend-icon
                  type="button"
                  :disabled="loading"
                  v-permissions="'generate-downaload-po-pi-for-accounts'"
                  color="orange"
                  @click="retryPO(props.item.assignmentId)"
                  :_id="props.item.assignmentId"
                >
                  <v-icon color="orange" small>settings</v-icon>Generate
                </button>
              </div>
            </v-flex>

            <v-flex
              v-else-if="
                props.item.voucherIdRealBooks &&
                props.item.workingCountry != 'PAK' &&
                (props.item.assignmentStatus != 1 ||
                  props.item.assignmentStatus != -1)
              "
            >
              <div>{{ props.item.voucherIdRealBooks }}</div>
            </v-flex>
            <v-flex v-else>
              <span v-if="!props.item.VendorWorkingCountry">N.A.</span>
              <span
                v-if="
                  props.item.VendorWorkingCountry != 'PAK' &&
                  props.item.assignmentStatus != 1
                "
                >N.A.</span
              >
              <v-tooltip
                v-if="
                  (props.item.VendorWorkingCountry == 'UAE' ||
                    props.item.VendorWorkingCountry == 'KSA') &&
                  props.item.assignmentStatus == 1
                "
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="assign--disable-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    <v-icon style="color: lightgray; !important" small
                      >settings</v-icon
                    >PO
                  </button></template
                >
                <span class="tooltip"
                  >Complete shipment to create purchase order.</span
                >
              </v-tooltip>
            </v-flex>
          </td>
          <td v-if="props.item.invoiceNumber == 'NA'">N.A.</td>
          <td v-else>
            <v-flex v-if="!props.item.VendorWorkingCountry">N.A.</v-flex>
            <v-flex v-else-if="props.item.VendorWorkingCountry == 'PAK'"
              >N.A.</v-flex
            >
            <v-flex v-else-if="!jobData.workOrderCompleted">
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="assign--disable-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    <v-icon style="color: lightgray; !important" small
                      >settings</v-icon
                    >PI
                  </button></template
                >
                <span class="tooltip"
                  >Generate PO to generate purchase invoice.</span
                >
              </v-tooltip></v-flex
            >
            <v-flex
              v-else-if="
                !props.item.isPurchaseInvoiceGenerated &&
                !props.item.invoiceNumber &&
                !props.item.isReadyToGeneratePurchaseInvoice &&
                props.item.VendorWorkingCountry != 'PAK'
              "
            >
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="grey"
              >
                <template slot="activator">
                  <button
                    class="assign--disable-ass-btn"
                    flat
                    prepend-icon
                    type="button"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    color="grey"
                    disabled
                  >
                    <v-icon style="color: lightgray; !important" small
                      >settings</v-icon
                    >PI
                  </button></template
                >
                <span v-if="!props.item.voucherIdRealBooks" class="tooltip"
                  >Generate SO to generate purchase invoice.</span
                >
                <span v-if="props.item.voucherIdRealBooks" class="tooltip"
                  >Complete shipment to generate purchase invoice.</span
                >
              </v-tooltip>
            </v-flex>
            <v-flex
              v-if="
                props.item.isReadyToGeneratePurchaseInvoice &&
                !props.item.isPurchaseInvoiceGenerated &&
                !props.item.isPurchaseOrderInQueue &&
                jobData.workOrderCompleted &&
                props.item.VendorWorkingCountry != 'PAK'
              "
            >
              <div v-if="props.item.showPurchaseInvoiceButton">
                <button
                  @click="open(props.item.assignmentId, props.item._id)"
                  v-permissions="'generate-downaload-po-pi-for-accounts'"
                  target="_blank"
                  v-if="!props.item.isPurchaseInvoviceBackgroundProcess"
                  class="assign-ass-btn"
                  title="Generate"
                >
                  <v-icon small color="orange">settings</v-icon>
                  Generate
                </button>
                <div
                  v-if="props.item.isPurchaseInvoviceBackgroundProcess"
                  style="word-break: break-word"
                >
                  Purchase Invoice can't be generated, Background process is
                  ongoing.
                </div>
                <div></div>
              </div>
              <div v-else>
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      class="assign--disable-ass-btn"
                      flat
                      prepend-icon
                      type="button"
                      color="grey"
                      disabled
                    >
                      <v-icon style="color: lightgray; !important" small
                        >settings</v-icon
                      >PI
                    </button></template
                  >
                  <span class="tooltip">please clear all advance</span>
                </v-tooltip>
              </div>
            </v-flex>

            <div v-else>
              <v-flex
                v-if="
                  props.item.invoiceNumber &&
                  props.item.isPurchaseInvoiceGenerated &&
                  jobData.workOrderCompleted &&
                  props.item.VendorWorkingCountry != 'PAK' &&
                  (props.item.assignmentStatus != 1 ||
                    props.item.assignmentStatus != -1)
                "
              >
                <div v-if="props.item.VendorWorkingCountry != 'PAK'">
                  {{ props.item.invoiceNumber }}
                </div>
              </v-flex>
              <v-flex
                v-if="
                  props.item.invoicePdfUrl &&
                  props.item.isPurchaseInvoiceGenerated &&
                  jobData.workOrderCompleted &&
                  props.item.VendorWorkingCountry != 'PAK'
                "
              >
                <div>
                  <button
                    v-if="props.item.isPurchaseInvoiceDownloadAllowed"
                    v-permissions="'generate-downaload-po-pi-for-accounts'"
                    class="download-ass-btn"
                    flat
                    type="button"
                    prepend-icon
                    :loading="loading"
                    :disabled="loading"
                    title="Download Invoice"
                    color="orange"
                    @click="
                      checkViewInvoice(
                        props.item.invoicePdfUrl,
                        props.item.isDownloadInvocie,
                        props.item.isPurchaseInvoviceBackgroundProcess,
                        props.item.invoiceNumber
                      )
                    "
                  >
                    <v-icon small color="white">download</v-icon>
                  </button>
                  <button
                    v-if="props.item.isPurchaseInvoiceDownloadAllowed"
                    v-permissions="'pi-download-control'"
                    class="download-ass-btn"
                    flat
                    type="button"
                    prepend-icon
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(false, props.item._id)"
                  >
                    Hide
                  </button>
                  <button
                    v-permissions="'pi-download-control'"
                    v-if="!props.item.isPurchaseInvoiceDownloadAllowed"
                    class="download-ass-btn"
                    flat
                    type="button"
                    prepend-icon
                    :loading="loading"
                    :disabled="loading"
                    title="hide"
                    color="orange"
                    @click="hideUnhidePI(true, props.item._id)"
                  >
                    Unhide
                  </button>
                </div>
              </v-flex>
            </div>
            <div
              v-if="
                !props.item.isPurchaseInvoiceGenerated &&
                props.item.VendorWorkingCountry != 'PAK' &&
                props.item.assignmentStatus != -1
              "
              class="pt-1"
            >
              <button
                v-if="props.item.isPurchaseInvoiceDownloadAllowed"
                v-permissions="'pi-download-control'"
                class="download-ass-btn"
                flat
                type="button"
                prepend-icon
                :loading="loading"
                :disabled="loading"
                title="hide"
                color="orange"
                @click="hideUnhidePI(false, props.item._id)"
              >
                Hide
              </button>
              <button
                v-permissions="'pi-download-control'"
                v-if="!props.item.isPurchaseInvoiceDownloadAllowed"
                class="download-ass-btn"
                flat
                type="button"
                prepend-icon
                :loading="loading"
                :disabled="loading"
                title="hide"
                color="orange"
                @click="hideUnhidePI(true, props.item._id)"
              >
                Unhide
              </button>
            </div>
          </td>
          <td v-if="props.item.salesInvoiceNumber == 'NA'">N.A</td>
          <td
            v-else-if="
              props.item.salesInvoiceNumber &&
              props.item.isSalesInvoiceGenerated
            "
          >
            {{ props.item.salesInvoiceNumber }}
            <v-layout style="text-align: center">
              <v-flex style="text-align: center">
                <button
                  v-permissions="'view-sales-invoice'"
                  class="download-ass-btn"
                  flat
                  type="button"
                  prepend-icon
                  :loading="loading"
                  :disabled="loading"
                  title="Download Invoice"
                  color="orange"
                  @click="
                    checkViewInvoice2(
                      props.item.salesInvoicePdfUrl,
                      props.item.isDownloadInvocie,
                      props.item.isSaleInvoviceBackgroundProcess,
                      props.item.salesInvoiceNumber
                    )
                  "
                >
                  <v-icon small color="white">download</v-icon>
                </button>
              </v-flex>
            </v-layout>
          </td>
          <td v-else>N.A.</td>
          <!-- <td>
            <v-layout
              v-if="
                props.item.paymentAmount && props.item.totalAdvancePaidAmount
              "
              class="justfy_c"
              ><span class="pd_5"
                >{{
                  props.item.paymentAmount && props.item.totalAdvancePaidAmount
                    ? (
                        props.item.paymentAmount +
                        props.item.totalAdditionalAmount -
                        props.item.totalAdvancePaidAmount
                      ).toFixed(2)
                    : "N.A"
                }}
                {{
                  props.item.paymentAmount && props.item.totalAdvancePaidAmount
                    ? props.item.paymentCurrency
                    : ""
                }}</span
              >
            </v-layout>
            <v-layout
              v-if="
                props.item.paymentAmount && !props.item.totalAdvancePaidAmount
              "
              class="justfy_c"
            >
              <span class="pd_5"
                >{{
                  props.item.paymentAmount && !props.item.totalAdvancePaidAmount
                    ? (
                        props.item.paymentAmount +
                        props.item.totalAdditionalAmount
                      ).toFixed(2)
                    : "N.A"
                }}
                {{
                  props.item.paymentAmount && !props.item.totalAdvancePaidAmount
                    ? props.item.paymentCurrency
                    : ""
                }}</span
              >
            </v-layout>
            <v-layout
              v-if="
                !props.item.paymentAmount && !props.item.totalAdvancePaidAmount
              "
              class="justfy_c"
              >N.A
            </v-layout>
          </td> -->
          <td v-if="props.item.transporterData">
            <v-icon
              color="red"
              class="white--text"
              small
              target="_blank"
              @click.stop="dialog = false"
              @click="
                checkTransporter(
                  props.item.transporterData.transporterId,
                  props.item.transporterData._id,
                  props.item.transporterData.workingCountry
                )
              "
              title="Transporter Details"
              >visibility</v-icon
            >
            {{ props.item.transporterData.transporterName || "N.A." }}
          </td>
          <td v-if="!props.item.transporterData">N.A.</td>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="invoiceDialog"
      persistent
      :max-width="500"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title>{{ headerVatPopup }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="invoiceDialog = false"
              :disabled="loading"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <div style="text-align: center">
            <div class="pb-4">
              <img
                src="../../assets/ConfirmationPi.svg"
                height="350"
                width="500"
                class="spMargin"
              />
            </div>
            <div>{{ messageVat }}</div>
          </div>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none; !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="confirmationPiGenerate('Without VaT')"
              >No</v-btn
            >
            <v-btn
              v-if="!vatInfo"
              color="grey"
              @click="invoiceDialog = false"
              text
              >Cancel</v-btn
            >
            <v-btn
              v-if="!vatInfo"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="confirmationPiGenerate('With VaT')"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPi"
      persistent
      :max-width="550"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title> Final Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="dialogPi = false"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text class="pa-4">
            {{ ConfirmMessageVat }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="vatInfo"
              medium
              style="text-transform: none; !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="dialogPi = false"
              >No</v-btn
            >
            <v-btn
              v-if="!FinalvatApply"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber(), (vatAmount = 0)"
              :disabled="loading"
              text
              >Yes</v-btn
            >

            <v-btn
              v-if="FinalvatApply"
              medium
              style="text-transform: none; !important"
              color="green white--text "
              v-permissions="'generate-purchase-invoice'"
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-card>
</template>

<script>
import PaginationButtons from "@/components/Pagination/Pagination-New";
import { accountAssignmentList } from "@/constants/datatable-headers.js";
import { EventBus } from "../../event-bus.js";
import activityLogs from "../Common/activityLogs";
import { StorageKeys } from "../../constants/constants";
import driverPrice from "./Popups/driverPrice";
import AdvancePaid from "./Popups/AdvancePaid";
import DriverDocStatus from "./Popups/DriverDocStatus";
import PendingAmount from "./Popups/PendingAmount";
import generateAdvancePopup from "../JobDocuments/generateAdvancePopup";
import confirmationPopup from "@/components/Pop-ups/confirmationPopup.vue";
import CancellationPopup from "@/components/Pop-ups/CancellationPopup.vue";
import Loading from "vue-loading-overlay";
import downloadMixin from "@/mixins/downloadMixin";
export default {
  mixins: [downloadMixin],
  components: {
    PaginationButtons,
    driverPrice,
    activityLogs,
    generateAdvancePopup,
    DriverDocStatus,
    PendingAmount,
    Loading,
    AdvancePaid,
    confirmationPopup,
    CancellationPopup,
  },
  props: {
    assignmentList: Array,
    jobId: String,
    jobData: Object,
  },
  watch: {
    assignmentList(val) {
      this.items = val;
    },
  },

  data() {
    return {
      subJobArray: [],
      assignments: [],
      headerVatPopup: "",
      messageVat: "",
      vatInfo: false,
      dialogPi: false,
      FinalvatApply: false,
      ConfirmMessageVat: "",

      vatAmount: null,
      loading: false,
      error: null,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      invoiceDialog: false,
      isAssignDriver: [],
      id: "",
      componentKey: 0,
      totalPages: 0,
      payload: {
        skip: "0",
        limit: "5",
        workingCountry: this.workingCountry,
      },
      headers: accountAssignmentList,
      items: [],
    };
  },
  created() {
    this.$eventBus.$on("accounts-purchase-invoice", (data) => {
      console.log();
      if (this.jobData.jobId == data.socketPurchInvoiceData.jobId) {
        this.refreshAssignment();
      }
    });
    this.$eventBus.$on("togglePurchaseInvoiceSwitch", (data) => {
      console.log(data);
      this.refreshAssignment();
    });

    this.$eventBus.$on("accounts-purchase-invoice2", (data) => {
      console.log(data);
      if (this.jobData.jobId == data.socketSalesInvoiceData.jobId) {
        this.refreshAssignment();
      }
    });
    this.$eventBus.$on("accounts-purchase-order", (data) => {
      if (this.$props.jobData.jobId == data.socketPurchData.jobId) {
        this.refreshAssignment();
      }
    });
    this.$eventBus.$on("accounts-sales-order", (data) => {
      if (this.$props.jobData.jobId == data.socketSalesOrderData.jobId) {
        this.refreshAssignment();
      }
    });
    this.$eventBus.$on("accounts-sales-invoice", (data) => {
      if (this.$props.jobData.jobId == data.socketSalesInvoiceData.jobId) {
        this.refreshAssignment();
      }
    });
    this.items = this.$props.assignmentList;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.payload = {
        skip: "0",
        limit: "5",
        workingCountry: this.workingCountry,
      };
    });
    this.payload = {
      skip: "0",
      limit: "5",
      workingCountry: this.workingCountry,
    };
  },
  methods: {
    Approve() {
      console.log("approve");
    },
    Reject() {
      console.log("Reject");
    },

    open(assignmentId, assId) {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        assignmentId: assId,
      };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/get-vat-details`, body, config)
        .then((response) => {
          console.log(response.data.data);
          if (response.data.data.amount > 0) {
            this.vatInfo = true;
            this.invoiceDialog = true;
            this.headerVatPopup = "Generate Purchase Invoice With VAT";
            this.messageVat = ` Do you want to generate Purchase Invoice with  VAT ${
              response.data.data.vatPercent
            } % (${response.data.data.amount.toFixed(2)} ${
              response.data.data.currency
            }) ?`;
            this.vatAmount = response.data.data.amount;
          } else {
            this.invoiceDialog = true;
            this.vatInfo = false;

            this.headerVatPopup = "Generate Purchase Invoice";
            this.messageVat = ` Do you want to generate Purchase Invoice ?`;
          }
        });

      this.loading = false;
      this.id = assignmentId;
    },
    async checkTransporterDetail(id, _id, driverId, workingCountry) {
      this.operationName = "view-profile-driver";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goToFromTransporterUserProfile(id, _id, driverId, workingCountry);
      } else {
        return;
      }
    },
    async check(n) {
      this.operationName = "view-profile-driver";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goToUserProfile(n);
      } else {
        return;
      }
    },

    hideUnhidePI(val, id) {
      console.log(val, id);
      this.loading = true;

      let url = "/assignments/purchase-invoice-switch";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentId: id.toString(),
        isPurchaseInvoiceDownloadAllowed: val,
      };

      this.axios.patch(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.refresh();
        },
        (err) => {
          this.loading = false;
        }
      );
    },

    confirmationPiGenerate(data) {
      console.log(data);
      if (data == "With VaT") {
        console.log("true");
        this.ConfirmMessageVat = "Are you sure you want to apply VAT?";
        this.dialogPi = true;
        this.FinalvatApply = true;
      } else {
        console.log("false");
        this.FinalvatApply = false;
        this.ConfirmMessageVat = "Are you sure you don't want to apply VAT?";
        this.dialogPi = true;
      }
    },

    checkTransporter(id, _id, country) {
      let route = this.$router.resolve({
        path: `/transporter/edit/${id}/${_id}/${country}`,
      });

      window.open(route.href, "_blank");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    async checkViewInvoice(
      url,
      isDownloadInvocie,
      isPurchaseInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.loading = true;
      this.operationName = "generate-downaload-po-pi-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        if (!isDownloadInvocie || isPurchaseInvoviceBackgroundProcess) {
          this.loading = false;
          this.error =
            "Purchase Invoice can't be downloaded, background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Purchase Invoice" + " " + invoiceNumber);
      } else {
        this.loading = false;
        return;
      }
    },
    async generateInvoiceNumber() {
      this.loading = true;
      this.operationName = "generate-downaload-po-pi-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        // this.loading = true;
        let url = "/dmsAdmin/generatePurchaseInvoice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { assignmentId: this.id.toString() };
        if (this.vatAmount > 0) {
          body.vatAmount = this.vatAmount;
        }
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.invoiceDialog = false;
            this.dialogPi = false;
            this.loading = false;
            this.refresh();
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
            this.invoiceDialog = false;
            this.dialogPi = false;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },

    async checkViewInvoice2(
      url,
      isDownloadInvocie,
      isPurchaseInvoviceBackgroundProcess,
      invoiceNumber
    ) {
      this.operationName = "view-sales-invoice";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (isPurchaseInvoviceBackgroundProcess) {
          this.loading = false;
          this.error =
            "Purchase Invoice can't be downloaded, background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.forceDownload(url, "Sales Invoice" + " " + invoiceNumber);
      } else {
        return;
      }
    },

    async retryPO(assignId) {
      this.operationName = "generate-downaload-po-pi-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        let url = "/dmsAdmin/generatePurchaseOrder";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { assignId: assignId.toString() };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.refresh();
            setTimeout(() => (this.dialog = false), 100);
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
      } else {
        return;
      }
    },
    refresh() {
      this.$emit("account-list-refresh");
    },
    refreshAssignment() {
      this.$emit("account-assign-list-refresh");
    },
    goToUserProfile(id) {
      let route = this.$router.resolve({ path: `/drivers/${id}` });

      window.open(route.href, "_blank");
    },
    goToFromTransporterUserProfile(ids, _id, driverId, workingCountry) {
      let route = this.$router.resolve({
        path: `/transporter/driver-edit/${ids}/${_id}/${driverId}/${workingCountry}/profile`,
      });

      window.open(route.href, "_blank");
    },
    paginationPayload(event) {
      this.payload = event;
    },
  },
};
</script>

<style scoped>
.assign-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid darkorange;
  padding: 2px 8px;
  border-radius: 5px;
}
.assign--disable-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 2px 8px;
  border-radius: 5px;
}
.spMargin {
  margin-top: -21px;
}
.track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
}
.download-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: blue;
  border-radius: 5px;
  border: 2px solid blue;
  padding: 2px 5px;
  margin-right: 2px;
}
</style>
