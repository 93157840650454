var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"nospace"},[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-data-table',{attrs:{"hide-actions":"","headers":_vm.headers,"width":"100% !important","hide-default-footer":"","items":_vm.items},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(props.item.actualAmountPaid)+" "+_vm._s(props.item.customerCurrency)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.employeeName)+"("+_vm._s(props.item.employeeId)+")"),_c('br'),_vm._v(" "+_vm._s(_vm.getTime3(props.item.updatedAt))+" ")]),_c('td',{staticStyle:{"min-width":"180px"}},[_vm._v(" "+_vm._s(props.item.modeOfPayment)+" "),(props.item.bankName && props.item.modeOfPayment != 'Cash')?_c('div',[_vm._v(" "+_vm._s(props.item.bankName)+" ")]):_vm._e()]),_c('td',{staticStyle:{"min-width":"180px"}},[_vm._v(" "+_vm._s(props.item.actualPaidDate && props.item.actualPaidTime ? _vm.getDate(props.item.actualPaidDate) + " " + props.item.actualPaidTime : "-")+" ")]),_c('td',{staticStyle:{"min-width":"180px"}},[(
            props.item.document &&
            props.item.document.length &&
            !props.item.isRetryPossibleForRbFailure
          )?_c('proof-of-delivery',{attrs:{"deliveryProof":props.item.document}}):_vm._e(),(props.item.isRetryPossibleForRbFailure)?_c('v-btn',{staticClass:"advance-btn-color",staticStyle:{"padding":"0px !important"},attrs:{"flat":"","color":"white"},on:{"click":function($event){return _vm.retryAdvanceAmount(props.item._id)}}},[_vm._v(" Retry")]):_vm._e(),(
            !props.item.isRetryPossibleForRbFailure &&
            props.item.advanceStatus == 'RB_Entry_Failed' &&
            props.item.errorFromRealBooks
          )?_c('div',{staticStyle:{"color":"red","display":"flex","justify-content":"center","align-items":"center","margin-top":"10px !important"}},[_c('v-tooltip',{staticStyle:{"margin":"0 10px 0 0 !important"},attrs:{"top":"","content-class":"tooltip-op"}},[_c('span',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","color":"white !important","background":"red !important","padding":"3px !important","border-radius":"100% !important","height":"20px !important","width":"20px !important","text-align":"center !important"},attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" i ")]),_c('span',{staticClass:"tooltip"},[_vm._v("Connect To Technical team")])]),_c('span',[_vm._v(" *"+_vm._s(props.item.errorFromRealBooks))])],1):_vm._e()],1)]}}])}),_c('success-dialog',{attrs:{"content":_vm.x.message,"heading":_vm.x.heading,"show":_vm.x.success,"onclose":_vm.closeMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }