<template>
  <div class="log">
    <v-btn
      v-if="!isCustomerReceiptInitiate"
      style="width: 30px; height: 30px"
      outline
      small
      fab
      @click="check()"
      :disabled="processing"
      color="orange"
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="40%">
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <!-- <loading :active.sync="loading" /> -->
          <v-toolbar fixed flat>
            <v-toolbar-title>Edit Driver Price </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="cancel()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer>
            <v-progress-linear
              indeterminate
              color="trukkin-theme darken-2"
              v-show="processing"
            ></v-progress-linear>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout row>
                  <v-flex xs1></v-flex>
                  <v-flex xs5>
                    <v-text-field
                      label="Price"
                      :suffix="currency"
                      v-model="x.paymentAmount"
                      type="number"
                      min="0"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>

                  <v-flex xs5 pt-3>
                    <v-text-field
                      label="Comments"
                      class="pt-0 currencyTitle"
                      v-model="x.comments"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>
                </v-layout>
              </v-container>
              <div
                class="my-3"
                v-if="haserror"
                style="
                  color: red;
                  width: 50%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ x.error }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                flat
                :disabled="processing"
                @click="cancel()"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                flat
                :disabled="processing"
                @click="addCharges()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../../event-bus.js";
import { StorageKeys, Currencies } from "../../../constants/constants";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
export default {
  data() {
    return {
      creditLimitError: "",
      AssignmentId: [],
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      operationName: "",
      x: {
        error: null,
        jobPrice: null,
        customerCurrency: null,
        comments: null,
        ids: null,
        paymentAmount: null,
      },
      amount: null,
      items: [],
      currencies: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      haserror: false,
      loading: false,
      rules: {
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
    };
  },
  props: {
    driverAmount: Number,
    currency: String,
    assignId: Number,
    isCustomerReceiptInitiate: Boolean,
    perAssignmentPrice: Number,
    assignmentStatus: String,
    advanceRequestedAmount: Number,
  },

  created() {
    this.x.error = null;
    this.haserror = false;
    this.currencies = Currencies;
  },
  components: {
    ErrorBox,
    CustomerCurrency,
    SuccessDialog,
  },
  methods: {
    open() {
      console.log(this.perAssignmentPrice, this.assignmentStatus);
      this.dialog = true;
      this.x.paymentAmount = this.$props.driverAmount;
      this.x.paymentCurrency = this.$props.currency;
    },
    checkPrice(id) {
      this.AssignmentId.forEach((e) => {
        if (e.value == id) {
          this.x.paymentAmount = e.price;
          return;
        }
      });
    },
    cancel() {
      this.dialog = false;
      this.haserror = false;
      this.x.paymentAmount = null;
      this.x.comments = null;
    },
    async check() {
      this.processing = true;
      this.operationName = "edit-driver-price-in-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.open();
      } else {
        this.processing = false;
        return;
      }
    },
    closeMessage() {
      this.processing = false;
      this.x.success = false;
      this.haserror = false;
    },

    async addCharges() {
      if (!navigator.onLine) {
        this.processing = false;
        this.x.error = "Please check your internet connection";
        this.haserror = true;
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      this.operationName = "edit-driver-price-in-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = true;
        this.haserror = false;
        if (this.x.paymentAmount == null) {
          this.processing = false;
          this.x.error = "Please enter price.";
          this.haserror = true;
          return;
        }

        if (this.x.paymentAmount >= 1000000000000) {
          this.processing = false;
          this.x.error = "Please enter amount less than 1000000000000";
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 10000);
          return;
        }
        console.log(this.advanceRequestedAmount);
        if (this.x.paymentAmount == 0 && this.advanceRequestedAmount) {
          this.processing = false;
          this.x.error = "Please cancel advance then  you fill 0 amount";
          this.haserror = true;
          return;
        }
        console.log(this.x.paymentAmount);
        console.log(this.perAssignmentPrice);
        if (Number(this.x.paymentAmount) > Number(this.perAssignmentPrice)) {
          this.processing = false;
          this.x.error = `Driver price should be less than the assignment amount ${this.perAssignmentPrice} ${this.$props.currency}`;
          this.haserror = true;
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return;
        }
        var value = /^[0-9]*$/.test(this.x.paymentAmount);
        if (!value) {
          this.processing = false;
          this.x.error = "Please enter valid price.";
          this.haserror = true;
          return;
        }
        let url = "/dmsAdmin/editDriverPrice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        var body = {
          assignmentId: this.$props.assignId.toString(),
          paymentCurrency: this.$props.currency,
          paymentAmount: this.x.paymentAmount.toString(),
        };
        if (this.x.comments) {
          body.comments = this.x.comments;
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.amount = null;
            this.processing = false;
            this.cancel();
            this.$emit("driver-price-list-refresh");
            this.x.error = null;
            this.error = null;
            this.haserror = false;
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.haserror = true;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}

.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
