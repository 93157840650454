<template>
  <div>
    <v-btn
      class="messagebtn"
      v-if="countInvoice != 0"
      @click.stop="OpenPopup()"
      title="Generate Invoice"
      style="background: orange !important; padding: 21px"
      flat
      target="_blank"
      color="white darken-1"
    >
      {{
        type == "Driver" ? "MAKE DRIVER PAYMENT" : "MAKE TRANSPORTER PAYMENT"
      }}
      ({{ countInvoice }})
    </v-btn>
    <v-btn
      class="messagebtn"
      @click.stop="dialog = true"
      title="Generate Invoice"
      style="background: orange !important; padding: 21px"
      flat
      target="_blank"
      :disabled="!assignmentIds.length"
      v-else
      prepend-icon
      color="white darken-1"
    >
      {{
        type == "Driver" ? "MAKE DRIVER PAYMENT" : "MAKE TRANSPORTER PAYMENT"
      }}
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="70%">
      <v-form
        ref="form"
        onsubmit="return false;"
        class="layout__spacing"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline" v-if="countInvoice > 1">
              Multiple Final Payment
              {{ type == "Driver" ? "To Driver" : "To Transporter" }}
            </span>
            <span class="headline" v-if="countInvoice == 1">
              Final Payment
              {{ type == "Driver" ? "To Driver" : "To Transporter" }}</span
            >
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer style="max-height: 550px !important">
            <v-card-text>
              <v-layout class="pb-3">
                <v-flex xs12 md12 v-if="type == 'Driver'">
                  <div class="sub-heading muted">
                    Driver’s Payment credit period :
                    <span style="color: black" class="heading"
                      >{{
                        alldata.driverPaymentDuration
                          ? alldata.driverPaymentDuration
                          : ""
                      }}
                      {{ alldata.driverPaymentDuration ? "days" : "N.A" }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3">
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Total Driver Price :
                    <span style="color: black" class="heading"
                      >{{ alldata.totalDriverPrice }}
                      {{ alldata.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Driver Price :
                    <span style="color: black" class="heading"
                      >{{ alldata.totalpaymentAmount }}
                      {{ alldata.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted">
                    Additional Charges :
                    <span style="color: black" class="heading break-word"
                      >{{ alldata.additionalCharge }}
                      {{ alldata.customerCurrency }}
                    </span>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3">
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Advance Paid :
                    <span style="color: black" class="heading"
                      >{{ alldata.totalAdvancePaidAmount }}
                      {{ alldata.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Amount Paid Till Date:
                    <span style="color: black" class="heading"
                      >{{ alldata.totalAmountPaid }}
                      {{ alldata.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs4 md4>
                  <div class="sub-heading muted break-word">
                    Pending Amount :
                    <span style="color: black" class="heading"
                      >{{ alldata.totalAmoutToPay }}
                      {{ alldata.customerCurrency }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs10>
                  <v-tabs
                    style="margin-bottom: 20px"
                    v-model="tabStatus"
                    fixed-tabs
                    background-color="darkorange"
                    dark
                    class="noMarginLeft main__tabs"
                  >
                    <v-tab
                      href="#Assignment"
                      style="text-transform: none; !important"
                    >
                      Assignment Payment
                    </v-tab>

                    <v-tab
                      v-if="type === 'Driver'"
                      href="#Driver"
                      style="text-transform: none; !important"
                    >
                      Driver Payment</v-tab
                    >
                    <v-tab
                      href="#Debtor"
                      style="text-transform: none; !important"
                    >
                      Payment Details
                    </v-tab>
                  </v-tabs>
                </v-flex>

                <v-flex xs5 class="pr-1 pt-2 pb-3 pt-100">
                  <div
                    class="noBorder upload-btn-wrapper"
                    v-if="tabStatus === 'Assignment'"
                  >
                    <div v-if="payAmount.length !== 1">
                      <button
                        v-if="!payAmount.some((e) => e.proof.length >= 5)"
                        class="btn"
                      >
                        <v-icon small color="orange">upload</v-icon> Add Same
                        Proof For All
                      </button>
                      <input
                        ref="fileUpload"
                        id="fileUpload"
                        type="file"
                        multiple
                        accept=".pdf, .jpeg, .jpg, .png"
                        @change="uploadDocument($event, 999)"
                      />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
              <div v-if="tabStatus === 'Assignment'">
                <v-data-table
                  id="ListTable"
                  hide-actions
                  :headers="headers"
                  :items="payAmount"
                  style="word-break: break-word"
                >
                  <template slot="items" slot-scope="props">
                    <td>{{ props.item.assId }}</td>
                    <td>
                      {{ props.item.totalDriverPrice }}
                      {{ props.item.currency }}
                    </td>
                    <td>
                      {{ props.item.amountPaidToDriver }}
                      {{ props.item.currency }}
                    </td>

                    <td>
                      <v-flex>
                        <v-text-field
                          v-model="props.item.amount"
                          type="number"
                          :rules="[rules.required, rules.minamount]"
                      /></v-flex>
                    </td>
                    <td>
                      <v-layout row>
                        <v-flex
                          xs2
                          class="pr-1 pt-2 pb-3"
                          v-if="props.item.proof.length < 5"
                        >
                          <div
                            class="noBorder upload-btn-wrapper"
                            v-if="tabStatus === 'Assignment'"
                          >
                            <button
                              v-if="props.item.proof.length < 5"
                              class="btn"
                            >
                              <v-icon small color="orange" class="outlined"
                                >upload</v-icon
                              >
                            </button>
                            <input
                              ref="fileUpload"
                              id="fileUpload"
                              type="file"
                              multiple
                              accept=".pdf, .jpeg, .jpg, .png"
                              @change="uploadDocument($event, props.item.i)"
                            />
                          </div>
                        </v-flex>

                        <v-flex pt-2>
                          <ul style="padding: 0px !important">
                            <li
                              style="position: relative"
                              :key="i"
                              v-for="(doc, i) in props.item.proof"
                              class="list"
                              ref="documentList"
                            >
                              <button
                                type="button"
                                v-show="props.item.proof"
                                @click="removeImage(i, props.item.i)"
                              >
                                <span class="close imageClose">&times;</span>
                              </button>
                              <a
                                :href="doc"
                                class="document-link"
                                v-if="
                                  doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'
                                "
                                target="_blank"
                              >
                                <img src="../../../assets/pfd.png" />
                              </a>
                              <a
                                :href="doc"
                                class="document-link"
                                target="_blank"
                                v-else
                              >
                                <img
                                  src="../../../assets/docimg.webp"
                                  height="72"
                                  width="75"
                                />
                              </a>
                            </li>
                          </ul>
                        </v-flex>
                      </v-layout>
                    </td>
                  </template>
                </v-data-table>
              </div>

              <v-layout v-if="tabStatus === 'Driver'">
                <v-flex xs12 md12>
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="paymentMode"
                    row
                    :mandatory="true"
                    readonly
                    :rules="[rules.required]"
                  >
                    <div>
                      <div class>
                        <v-layout>
                          <v-radio
                            label="Cash"
                            value="Cash"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>

                          <v-radio
                            label="Bank Transfer"
                            value="Bank Transfer"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>
                        </v-layout>
                      </div>
                    </div>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout v-if="tabStatus === 'Driver'">
                <v-flex md5 class="payadvance">
                  <v-flex md12 pb-3>
                    <v-text-field
                      v-model="accountHolderName"
                      label="Account Holder Name"
                      placeholder="Enter account holder name"
                      class="pt-0 currencyTitle"
                      :value="accountHolderName"
                      readonly
                    ></v-text-field>
                  </v-flex>

                  <v-layout>
                    <v-flex md12 class="payadvance">
                      <v-text-field
                        v-model="ifscCode"
                        label="IBAN Number"
                        placeholder="Enter IBAN number"
                        readonly
                        class="pt-0 currencyTitle"
                        maxlength="25"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex md6 pl-5 class="payadvance">
                  <v-layout>
                    <v-flex md12 pb-3 class="payadvance">
                      <v-text-field
                        v-model="accountNumber"
                        label="Account Number"
                        readonly
                        placeholder=" Enter Account Number"
                        class="pt-0 currencyTitle"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-layout>
                      <v-flex md12 class="payadvance">
                        <v-text-field
                          v-model="swiftNumber"
                          label="Swift Number"
                          class="pt-0 currencyTitle"
                          readonly
                          placeholder="Enter Swift Number"
                          :value="swiftNumber"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex xs12 md12>
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="debtorPaymentMode"
                    row
                    :mandatory="true"
                    :rules="[rules.required]"
                    @change="getBankName()"
                  >
                    <div>
                      <div class>
                        <v-layout>
                          <v-radio
                            label="Cash"
                            value="Cash"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>

                          <v-radio
                            label="Bank Transfer"
                            value="Bank Transfer"
                            color="orange"
                            class="col-md-6"
                          ></v-radio>
                        </v-layout>
                      </div>
                    </div>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout v-if="tabStatus === 'Debtor'"> </v-layout>

              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex xs5 pt-3>
                  <span class="muted"></span>
                  <v-menu
                    ref="FilterDateBool"
                    lazy
                    v-model="FilterDateBool"
                    class="date__icon"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    full-width
                    :nudge-right="40"
                    min-width="290px"
                    :return-value.sync="startDate"
                  >
                    <v-text-field
                      slot="activator"
                      :label="'Select Actual Date '"
                      placeholder="Select Actual Date"
                      v-model="startDate"
                      prepend-icon="event"
                      readonly
                      class="pt-0 currencyTitle"
                      :rules="[rules.date]"
                    ></v-text-field>
                    <v-date-picker
                      v-model="startDate"
                      :max="dateToday"
                      class="minHeight"
                      @change="$refs.FilterDateBool.save(startDate)"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs5 pl-5 style="position: relative">
                  <label
                    :class="
                      actualModeError
                        ? 'light_red label__position__acc muted'
                        : 'label__position__acc muted'
                    "
                    style="font-size: 10px !important"
                    >Select Actual Time</label
                  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    :class="
                      actualModeError
                        ? 'light_red_fill clock_account_pay'
                        : 'clock_account_pay'
                    "
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                  </svg>
                  <vue-timepicker
                    class="fonttime fonttime4"
                    :minute-interval="30"
                    v-model="pickupTime"
                    input-width="100%"
                    placeholder="Select Actual Time"
                    close-on-complete
                  ></vue-timepicker>
                  <br />
                  <span
                    v-if="actualModeError"
                    style="
                      color: #ff5252 !important;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                      font-size: 12px;
                    "
                  >
                    {{ errorMessage }}
                  </span>
                </v-flex>
              </v-layout>
              <v-layout v-if="tabStatus === 'Debtor'">
                <v-flex
                  xs5
                  class="pading-34"
                  pt-3
                  v-if="debtorPaymentMode === 'Cash'"
                >
                  <v-autocomplete
                    v-if="
                      alldata.customerCurrency === 'AED' ||
                      alldata.customerCurrency === 'SAR'
                    "
                    label="Select Cash"
                    placeholder="Select Cash"
                    :items="banks"
                    class="pt-0 currencyTitle"
                    v-model="bankName"
                    item-text="text"
                    item-value="value"
                  />

                  <v-text-field
                    v-if="alldata.customerCurrency == 'PKR'"
                    v-model="bankName"
                    label="Select Cash"
                    class="pt-0 currencyTitle"
                    placeholder="Select Cash"
                    :value="bankName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5 class="pading-34" pt-3 v-else>
                  <v-autocomplete
                    v-if="
                      alldata.customerCurrency === 'AED' ||
                      alldata.customerCurrency === 'SAR'
                    "
                    label="Choose Bank Detail"
                    placeholder="Choose Bank Detail"
                    :items="banks"
                    class="pt-0 currencyTitle"
                    v-model="bankName"
                    item-text="text"
                    :rules="[rules.debtorBank]"
                    item-value="value"
                  />

                  <v-text-field
                    v-if="alldata.customerCurrency == 'PKR'"
                    v-model="bankName"
                    label="Bank Name"
                    class="pt-0 currencyTitle"
                    placeholder="Bank Name"
                    :value="bankName"
                    :rules="[rules.noWhiteSpace, rules.debtorBankName]"
                  ></v-text-field>
                </v-flex>

                <v-flex xs5 pt-3 pl-5>
                  <v-text-field
                    :rules="[rules.comment]"
                    class="pt-0 currencyTitle"
                    label="Comments"
                    placeholder="Comments"
                    v-model="Comments"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-spacer>
          <v-card-actions>
            <v-layout>
              <v-spacer />
              <v-btn
                flat
                @click.native="close()"
                style="text-transform: none;!important"
                >Close</v-btn
              >
              <v-btn
                :loading="processing"
                style="text-transform: none;!important"
                color="orange darken-2"
                class="white--text"
                @click="submit()"
                >Pay</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <success-dialog
      :content="x.message"
      :show="x.success"
      :heading="x.heading"
      :onclose="closeMessage"
    />
    <v-snackbar
      :timeout="4000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>

<script>
import { StorageKeys } from "../../../constants/constants";
import { EventBus } from "../../../event-bus.js";
import { assignmentMultiplePayment } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import moment from "moment";
import {
  addProofOfDelivery,
  uploadImage,
} from "../../../constants/api-urls.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
export default {
  data() {
    return {
      error: "",
      dialog: false,
      headers: assignmentMultiplePayment,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      invoiceDialog: false,
      bankDialog: false,
      loading: false,
      selected: [],
      single: "",
      items: [],
      paymentMode: "",
      actualModeError: false,
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      paymentType: null,
      accountHolderName: "",
      tabStatus: "Assignment",
      swiftNumber: "",
      startDate: "",
      FilterDateBool: false,
      pickupTime: {
        HH: "",
        mm: "",
      },
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,
        heading: "Final Payment Update",
        error: null,
        missingField: null,
      },

      accountNumber: "",
      ifscCode: "",
      advanceAmount: "",
      debtorPaymentMode: "Cash",
      Comments: "",
      banks: [],
      cheque: "",
      draftNo: "",
      payAmount: [
        {
          i: 0,
          assignment_id: null,
          amount: 0,
          paymentAmount: 0,
          proof: [],
        },
      ],
      operationName: "",
      processing: false,
      advanceAmount: "",
      dialog: false,
      errorMessage: "Select Actual Time is required",
      voucher: "",
      alldata: [],
      adjustAmount: [],
      document: [],
      valid: true,
      mainUpload: true,
      lazy: false,
      documents: [],
      error: null,
      gpsError: null,
      driverAmount: "",

      banks: [],

      bankName: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        debtorBank: (value) => !!value || "Bank is required.",
        selectCash: (value) => !!value || "Select Cash Field is required.",
        debtorBankName: (value) => !!value || " Bank Name is required.",
        amountToBePaid: (value) => !!value || "Amount To be Paid is required.",
        date: (value) => !!value || " Select Actual Date is required.",
        comment: (value) => value != "" || " Comment  is required.",
        minamount: (v) => (v && v >= 1) || "Enter valid value",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
    };
  },
  components: {
    VueTimepicker,
    SuccessDialog,
  },
  watch: {
    pickupTime(val) {
      //  this.actualModeError = false;

      if (val.HH || val.mm) {
        this.checkTime();
        this.actualModeError = false;
      }
    },

    payAmount(val) {
      this.payAmount.forEach((e) => {
        if (e.proof.length >= 5) {
          this.mainUpload == false;
        }
      });
    },
  },
  props: {
    assignmentIds: Array,
    countInvoice: Number,
    type: String,
    selectedCustomerForInvoice: Object,
  },
  methods: {
    close() {
      this.selected = [];
      this.loading = false;
      this.dialog = false;
    },
    closeMessage() {
      this.x.success = false;
      // console.log("try close");
    },
    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;

      var time = this.pickupTime.HH + ":" + this.pickupTime.mm;

      if (time != "" && !time.match(re)) {
        this.actualModeError = true;

        return false;
      }
    },
    checkAmount() {
      this.processing = true;
      return this.payAmount.some((e) => e.amount > e.paymentAmount);
    },

    async submit() {
      if (!this.checkAmount()) {
        this.operationName = "pending-amount-for-driver-for-accounts";
        let y = await this.checkOpertaionPermission(this.operationName);

        if (y) {
          this.error = null;
          this.processing = true;
          if (this.$refs.form.validate()) {
            if (
              this.pickupTime.HH == "" &&
              this.pickupTime.mm == "" &&
              this.tabStatus === "Debtor"
            ) {
              this.actualModeError = true;
              (this.errorMessage = "Select Actual Time is required"),
                (this.processing = false);
              return false;
            }
            if (
              !this.debtorPaymentMode ||
              !this.startDate ||
              !this.Comments ||
              this.pickupTime === { HH: "", mm: "" } ||
              !this.pickupTime
            ) {
              this.processing = false;
              this.error = "Please fill debtor details!";

              return false;
            }

            if (this.debtorPaymentMode === "Bank Transfer" && !this.bankName) {
              this.processing = false;

              this.error = "Please fill debtor details!";
              return false;
            }

            if (this.pickupTime === { HH: "", mm: "" }) {
              (this.errorMessage = "Select Actual Time is required"),
                (this.actualModeError = true);
              return false;
            }
            if (this.pickupTime.HH != " " && this.pickupTime.mm != "") {
              this.actualModeError = false;
              this.checkTime();

              let currentDate = moment(new Date()).format("YYYY-MM-DD, HH:mm");
              let selectedDateTime =
                this.startDate +
                "," +
                this.pickupTime.HH +
                ":" +
                this.pickupTime.mm;

              if (
                new Date(currentDate).getTime() <
                new Date(selectedDateTime).getTime()
              ) {
                this.processing = false;
                this.error =
                  "Please select the past time and date before moving ahead!";
                return false;
              }
            }

            let url = "/dmsAdmin/multiple-driver-final-payment";

            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            let config = {
              headers: {
                authorization: `bearer ${token}`,
              },
            };
            const body = {
              paymentMode: this.debtorPaymentMode.toString(),
              paidDate: this.startDate,
              paidTime: this.pickupTime.HH + ":" + this.pickupTime.mm,
              assignmentIdAmount: this.payAmount,
              comment: this.Comments.trim(),
            };
            if (this.bankName) {
              if (
                (this.items[0].invoicingCompany == "2" &&
                  this.debtorPaymentMode == "Cash") ||
                (this.items[0].invoicingCompany == "4" &&
                  this.debtorPaymentMode == "Cash")
              ) {
              } else {
                body.bankName = this.bankName;
              }
            }
            // console.log(this.items[0].invoicingCompany);
            // console.log(this.bankName);

            // console.log(this.bankName && this.items[0].invoiceCompany == "1");
            if (
              (this.bankName && this.items[0].invoicingCompany == "1") ||
              (this.bankName && this.items[0].invoicingCompany == "2") ||
              (this.bankName && this.items[0].invoicingCompany == "4")
            ) {
              if (
                (this.items[0].invoicingCompany == "2" &&
                  this.debtorPaymentMode == "Cash") ||
                (this.items[0].invoicingCompany == "4" &&
                  this.debtorPaymentMode == "Cash")
              ) {
              } else {
                body.ledgerName = this.bankName;
              }
            }

            this.axios.post(this.constants.apiUrl + url, body, config).then(
              (response) => {
                this.$emit("payment-list-refresh-multiple");
                this.dialog = false;
                this.processing = false;
                this.close();
                this.paymentMode = "";
                this.bankName = "";
                this.startDate = "";
                this.pickupTime = {
                  HH: "",
                  mm: "",
                };
                this.Comments = "";
                this.error = "";
                this.x.message = response.data.data.message;
                this.x.heading = "Final Payment Update";
                this.x.success = true;
              },
              (error) => {
                this.processing = false;
                this.error = error.response.data.message;
              }
            );
          } else {
            this.processing = false;
            if (
              this.pickupTime.HH == "" &&
              this.pickupTime.mm == "" &&
              this.tabStatus === "Debtor"
            ) {
              this.actualModeError = true;
              (this.errorMessage = "Select Actual Time is required"),
                (this.processing = false);
              return false;
            }
          }
        } else {
          this.processing = false;
          return;
        }
      } else {
        this.processing = false;
        let valpayment = [];
        valpayment = this.payAmount.filter((e) => {
          if (e.amount > e.paymentAmount) {
            return e.assId;
          }
        });
        // console.log(valpayment.map((e) => e.assId).toString());
        this.error =
          "You cannot pay greater than the amount to be paid to the driver.";
      }
    },
    OpenPopup() {
      // console.log(this.$props.type);
      this.getAssignmentsDetails();
    },

    getBankName() {
      if (
        (this.items[0].invoicingCompany == "2" &&
          this.debtorPaymentMode == "Cash") ||
        (this.items[0].invoicingCompany == "4" &&
          this.debtorPaymentMode == "Cash")
      ) {
        this.banks = [];
        this.banks = ["Cash"];
      } else {
        // if (this.items[0].invoicingCompany == "1") {
        this.banks = [];
        let obj = {
          Cash: "cash",
          "Bank Transfer": "bankTransfer",
        };

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          country: this.items[0].invoicingCompany,
          modeOfPayment:
            this.items[0].invoicingCompany == "2"
              ? "bankTransfer"
              : obj[this.debtorPaymentMode],
        };
        let { apiUrl } = this.constants;
        this.axios
          .post(`${apiUrl}/dmsAdmin/get-ledger-details`, body, config)
          .then(
            (response) => {
              const { data = null } = response.data;
              data.forEach((element) => {
                this.banks.push({
                  text: element.ledgerName,
                  value: element.ledgerName,
                });
              });

              // this.bankName = response.data.data[2].bankName;
              // this.banks = response.data.data;
            },
            (error) => {
              this.error = "Something went wrong. Please try again later!";
              this.loading = false;
            }
          );
      }
      // } else if (
      //   this.items[0].invoicingCompany == "2" &&
      //   (this.items[0].invoicingCity == "1" ||
      //     this.items[0].invoicingCity == "2")
      // ) {
      //   this.banks = ["Mashreq Bank PSJC", "EMIRATES NBD Bank", "ADCB -  0001"];
      // } else {
      //   return;
      // }
    },
    // Driver Details
    fillDriverDetails(items) {
      this.paymentMode = items[0].creditorPaymentMode;
      this.ifscCode = items[0].creditorIBANNumber;
      this.swiftNumber = items[0].creditorSwiftNo;
      this.accountHolderName = items[0].accountHolderName;
      this.accountNumber = items[0].creditorAccountNumber;
      this.voucher = items[0].creditorVoucherNo;
      this.draftNo = items[0].creditorDraftNo;
      this.cheque = items[0].creditorChequeNo;
      this.tabStatus = "Assignment";
    },

    // Assignments Details fetch
    getAssignmentsDetails() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentIds: this.$props.assignmentIds,
      };
      let { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/get-driver-final-payment`, body, config)
        .then(
          (response) => {
            this.dialog = true;
            this.alldata = response.data.data;

            this.items = response.data.data.list;
            this.fillDriverDetails(this.items);
            this.getBankName();
            this.items.filter((e) => {
              if (e.additionalChargeAmount) {
                e.paymentAmount = e.paymentAmount + e.additionalChargeAmount;
              }
              if (e.totalAdvancePaidAmount) {
                e.paymentAmount = e.paymentAmount - e.totalAdvancePaidAmount;
              }
            });

            let invoiceList = [];

            invoiceList = this.items.map((it, index) => {
              return {
                i: index,
                assignmentId: it._id,
                amount: it.paymentAmount,
                paymentAmount: it.paymentAmount,
                assId: it.assignmentId,
                totalDriverPrice: it.totalDriverPrice,
                amountPaidToDriver: it.amountPaidToDriver,
                currency: it.customerCurrency,
                proof: [],
              };
            });

            this.payAmount = [];
            this.payAmount.push(...invoiceList);

            // this.bankName = response.data.data[2].bankName;
          },
          (error) => {
            this.error = response.data.message;
            this.loading = false;
          }
        );
    },
    mainImages() {
      const array = [1, 2, 3, 4, 5];

      // checks whether an element is even

      if (this.payAmount.some(element.proof.length > 5));
      {
        return false;
      }

      // expected output: true
    },
    // remove images
    removeImage(key, i) {
      this.payAmount[i].proof.splice(key, 1);
    },
    uploadDocument(e, id) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i], id);
      });
    },
    createImage(file, id) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.error = "Please upload proof with size less than 5MB!";
        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.error = "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file, id);
      }
    },
    upload(file, id) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (id == 999) {
              if (this.payAmount) {
                this.payAmount.forEach((e) => {
                  if (e.proof.length < 5) {
                    e.proof.push(response.data.data.original);
                  }
                });
              }
            } else {
              if (this.payAmount[id].proof.length < 5) {
                this.payAmount[id].proof.push(response.data.data.original);
              } else {
                this.error = "Maximum 5 proofs can be uploaded!";
                return false;
              }
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.processing = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.break-word {
  word-break: break-all;
}
.pay-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid orange;
  padding: 2px 12px;
  border-radius: 5px;
}
.display-block {
  display: block !important;
}
.pading-34 {
  padding-left: 34px !important;
}
.pay-not-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 4px 12px;
  border-radius: 5px;
}
.custom-file-upload {
  border: none;
  display: inline-block;
  background: orange;
  font-size: 16px;
  color: #fff;
  padding: 10px 12px;
  cursor: pointer;
}
.document-link {
  text-decoration: none;
}
.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
.fonttime {
  font-size: 12px !important;
}
.list {
  display: inline-block;
  position: relative;
}
.fonttime .dropdown.drop-down {
  top: -14%;
}
::v-deep .fonttime4 input::placeholder {
  font-size: 12px !important;
  color: #b5b5b5;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
}
</style>

<style scoped>
.invoice-pop {
  padding: 20px !important;
}
.fonthead {
  font-size: 20px;
}
</style>
